import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useGetSales, {
  ChannelStatsReportResultsV2,
  EModules,
  // IAPIChannelStatsReport,
  TChannelStatsReportOrderKeys,
  getSalesAsync,
} from "api/useGetSales";
import { IDateRangeProps } from "components/DateRange";
import Table from "components/Table";
import { ESortOrder, ISortState, TSort } from "components/Table/types";
import { ICheckListFilterProps } from "components/Table/components/CustomizeFilters/types";
import {
  IHeadObjectXLS,
  TActionProps,
  TGeneratePdfFunction,
  TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import apiDateFormat from "constants/apiDateFormat";
import { pageSizeOptions } from "constants/defaultPageSizes";
import { website } from "constants/providerFilters";
import { useDebounce } from "hooks";
// import { EAPIProviderRanks, TProviders } from "types/APIProviderRanks";
import { IProviderCheckList } from "types/IProviderCheckList";
import { filterColumnsV2 } from "utils/filterColumns";
import {
  ELocalSavedFilters,
  ISavedDefaultFilters,
  commonTableFilters,
  getDefaultFilters,
  setDefaultFilters,
} from "utils/defaultFilters";
import formatOrderingAPI from "utils/formatOrderingAPI";
// import { ChannelStatsItem, IProvider } from "./types";
import { columns, columnsNew, pdfColumns, pdfColumnsNew, xlsColumns, xlsColumnsNew } from "./constants";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import useUrlQuery from "hooks/useUrlQuery";
import { ChannelStatsUrlParams } from "utils/urlParams";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

const channelStatsProviders = [website];

type TSavedFilters = ISavedDefaultFilters[ELocalSavedFilters.ChannelStatsTable];

const ChannelStats = () => {

  const userAuth = useAppSelector(selectUserData);

  const { getParam, getMultipleParams, setMultipleParams } = useUrlQuery<ChannelStatsUrlParams>();

  const startDateParams = getParam("startDate");
  const endDateParams = getParam("endDate");
  const sourceParams = getMultipleParams("source");
  const sortByParams = getParam("sortBy");
  const searchParams = getParam("search");
  
  const defaultFilters = getDefaultFilters<TSavedFilters>(
    ELocalSavedFilters.ChannelStatsTable,
    { ...commonTableFilters, providers: channelStatsProviders }
  );

  const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
    {
      startDate: startDateParams ? new Date(startDateParams) : defaultFilters.dateRange.startDate,
      endDate: endDateParams ? new Date(endDateParams) : defaultFilters.dateRange.endDate,
    }
  );

  // const [offset, setOffset] = useState<number>(defaultOffset);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);
  const [providers, setProviders] = useState<IProviderCheckList[]>(
    defaultFilters.providers
  );

  const [sortBy, setSortBy] = useState<ISortState>(sortByParams && typeof sortByParams === 'string' ? 
    {
      column: sortByParams.replace("-", ""),
      order: sortByParams.startsWith("-") ? ESortOrder.Descending : ESortOrder.Ascending,
    } 
    : {
    column: null,
    order: ESortOrder.None,
  });

  const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");
  const quickSearchApi = useDebounce<string>(quickSearch, 350);

  useEffect(() => {    

    const selectedProviders = providers.filter(({ value }) => value).map(({ id }) => id);

    setMultipleParams({ 
      startDate: format(dateRange.startDate, apiDateFormat),
      endDate: format(dateRange.endDate, apiDateFormat),
      source: selectedProviders,
      inventory: userAuth.inventory.value,
      ...(sortBy.order && sortBy.column && {
        sortBy: sortBy.order === ESortOrder.Ascending ? sortBy.column : `-${sortBy.column}`,
      }),
      search: quickSearchApi
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.endDate, dateRange.startDate, userAuth.inventory.value, sortBy.column, sortBy.order, quickSearchApi]);

  // get 3rd party providers
  const fetchProvders = useMemo(() => ({
    module: EProvidersModules.ThirdPartyProviderV2,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      inventory: userAuth.inventory.value
    }
  }), [userAuth.dealerV2.uid, userAuth.inventory.value]);

  const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);
  
  // set providers
  useMemo(() => {
    if (!providerList.isFetching && providerList.data) {
      const tempProviders: IProviderCheckList[] = ((providerList.data as any).results as any[]).map((provider) => {
        return {
          id: provider.value,
          label: provider.label,
          value: sourceParams?.includes(provider.value.toString()) || false,
        }
      });  
          
      tempProviders.push(website);

      setProviders(tempProviders);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerList.data, providerList.isFetching]);

  const fetchParameters = useMemo(
    () => ({
      module: EModules.ChannelStatsReportV2,
      params: {
        date_from: format(dateRange.startDate, apiDateFormat),
        date_to: format(dateRange.endDate, apiDateFormat),
        dealership_uid: userAuth.dealerV2.uid,
        page: page + 1,
        // limit: pageSize,
        page_size: pageSize,
        ordering: formatOrderingAPI<TChannelStatsReportOrderKeys>(sortBy),
        search: quickSearchApi,
        source: providers.filter(({ value }) => value).map(({ id }) => id),
        inventory: userAuth.inventory.value
      },
    }),
    [
      dateRange.endDate,
      dateRange.startDate,
      // offset,
      pageSize,
      providers,
      quickSearchApi,
      sortBy,
      userAuth.dealerV2.uid,
      page,
      userAuth.inventory.value
    ]
  );
  const { data, isFetching } = useGetSales<EModules.ChannelStatsReportV2>(fetchParameters);

  useEffect(() => {
    setDefaultFilters<TSavedFilters>(ELocalSavedFilters.ChannelStatsTable, {
      dateRange,
      pageSize,
      providers,
    });
  }, [dateRange, pageSize, providers]);

  const onChangeDateRange: IDateRangeProps["onChange"] = useCallback(
    ([startDate, endDate]) => {
      setDateRange({ startDate, endDate });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onOffsetChange = useCallback((newOffset: number) => {
    // console.log('newOffset', newOffset);
    // setOffset(newOffset);
  }, []);

  const onPageIndexChange = useCallback((newPageIndex: number) => {
    setPage(newPageIndex);
  }, []);

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const onChangeProviders = useCallback(
    (newValues: ICheckListFilterProps["options"]) => {
      setProviders(newValues as unknown as IProviderCheckList[]);
      setSortBy({ column: null, order: ESortOrder.None });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSort: TSort["onSort"] = useCallback(
    ({ column, order }: ISortState) => {
      setSortBy({ column, order });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onChangeQuickSearch = useCallback(
    (newSearch: string) => {
      setQuickSearch(newSearch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const formattedData: ChannelStatsReportResultsV2[] = data?.results ?? [];
  
  // const formattedData: ChannelStatsItem[] =
  //   data?.results?.map((value) => {
  //     const getProviderStats = (
  //       dataResult: IAPIChannelStatsReport["channel_stats"],
  //       provider: TProviders
  //     ): IProvider => {
  //       const providerValues = dataResult.find(
  //         ({ source }) => source === provider
  //       );

  //       return {
  //         dealRating: providerValues?.deal_rating || EAPIProviderRanks.Unknown,
  //         price: providerValues?.price || 0,
  //         ratio: providerValues?.srp_vdp_ratio || 0,
  //         srp: providerValues?.srps || 0,
  //         vdp: providerValues?.vdps || 0,
  //       };
  //     };

  //     return {
  //       autotrader: getProviderStats(value.channel_stats, "autotrader"),
  //       carfax: getProviderStats(value.channel_stats, "carfax"),
  //       cargurus: getProviderStats(value.channel_stats, "cargurus"),
  //       carsDotCom: getProviderStats(value.channel_stats, "cars_com"),
  //       description: value.description,
  //       dol: value.dol,
  //       photos: value.photos_count,
  //       stockNumber: value.stock_number,
  //       vehicle: `${value.year} ${value.make} ${value.model}`,
  //       website: getProviderStats(value.channel_stats, "website"),
  //     };
  //   }) || [];

  const filteredColumns = useMemo(
    () => filterColumnsV2(userAuth.inventory.value === 'new' ? columnsNew : columns, providers),
    [providers, userAuth.inventory.value]
  );

  const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
    async (generateXLS, setIsLoading) => {
      setIsLoading(true);

      try {
        // let hasNextPage = true;
        let collectedResults: ChannelStatsReportResultsV2[] = [];
        // let mapOffset = defaultOffset;

        let hasNextPage = true;

        let tempPage = 1;

        do {
          const { next, results } = await getSalesAsync<EModules.ChannelStatsReportV2>({
              ...fetchParameters,
              params: { 
                ...fetchParameters.params,
                page: tempPage
                // offset: mapOffset,
              },
            })();

          collectedResults = [...collectedResults, ...results];

          tempPage++;
          // console.log('next', next);

          hasNextPage = next ? true : false;
          // mapOffset = mapOffset + defaultPageSize;
        } while (hasNextPage);

        let groupedHeadFormatted: [IHeadObjectXLS[], string[]] = [[], []];

        // filter providers
        const filteredProviders = providers.filter(({ value }) => value);

        // filter columns
        const filteredXlsColumns = (userAuth.inventory.value === 'new' ? xlsColumnsNew : xlsColumns).map(column => {
          if (column.columns) {

            column.columns = column.columns.filter(groupedColumn =>
              !groupedColumn.filterColumnId || filteredProviders.some(provider => provider.id === groupedColumn.filterColumnId)
            );
          }
          return column;
        })
        
        .filter(column => 
          !column.filterColumnId || filteredProviders.some(provider => provider.id === column.filterColumnId)
        );
        
        // rest of the code remains the same
        const bodyFormatted = collectedResults.map((values) => {
          const groupedColumns = filteredXlsColumns
            // .filter(({ filterColumnId: columnFilterId }) => columnFilterId === filterColumnId)
            .map(({ columns: groupColumns, func, key, filterColumnId }) => {
              if (!groupColumns) {
                return (func?.(values[key], values) as string) || (values[key] as string);
              } else {
                return groupColumns.map(
                  ({ func, key }) => {
                    // console.log('func', values);
                    return (func?.(values[key], values) as string) || (values[key] as string)
                  }
                );
              }
            }
              
          );

          return groupedColumns.flat();
        });

        for (
          let indexHeadColumn = 0;
          indexHeadColumn < filteredXlsColumns.length;
          indexHeadColumn++
        ) {
          const {
            colspan,
            label,
            columns: groupedColumns,
          } = filteredXlsColumns[indexHeadColumn];
          
          groupedHeadFormatted[0].push({ colspan, label });
        
          if (groupedColumns && groupedColumns.length) {
            groupedColumns.map(({ label }) =>
              groupedHeadFormatted[1].push(label)
            );
          }
        }

        generateXLS(
          {
            head: groupedHeadFormatted,
            body: bodyFormatted,
          },
          "channel-stats-report.xls"
        );
        setIsLoading(false);
      } catch (err) {
        console.log("err", err);
        toast.error("Can't export XLS file. Please contact your admin");
      }
    },
    [fetchParameters, providers, userAuth.inventory.value]
  );

  const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
    async (onGeneratePDF, setIsLoading, provider) => {
      setIsLoading(true);

      console.log('provider', provider);

      try {
         // let hasNextPage = true;
         let collectedResults: ChannelStatsReportResultsV2[] = [];
         // let mapOffset = defaultOffset;
 
         let hasNextPage = true;
 
         let tempPage = 1;
 
         do {
           const { next, results } = await getSalesAsync<EModules.ChannelStatsReportV2>({
               ...fetchParameters,
               params: { 
                 ...fetchParameters.params,
                 page: tempPage
                 // offset: mapOffset,
               },
             })();
 
           collectedResults = [...collectedResults, ...results];
 
           tempPage++;
           // console.log('next', next);
 
           hasNextPage = next ? true : false;
           // mapOffset = mapOffset + defaultPageSize;
         } while (hasNextPage);

          //  filter providers
        //  const filteredProviders = providers.filter(({ value }) => value);

        //  // filter pdf base pm filterColumnId that exist in filteredProviders
        //  const filteredPDFColumns = (userAuth.inventory.value === 'new' ? pdfColumns : pdfColumns)
        //  .filter(({ filterColumnId }) => filterColumnId || filteredProviders.some(provider => provider.id === filterColumnId));

        // filter providers
        
        const tempPdfColumns = userAuth.inventory.value === 'new' ? pdfColumnsNew : pdfColumns;

        const filteredPDFColumns = tempPdfColumns
          .filter(({ filterColumnId }) => !filterColumnId || filterColumnId === provider?.id);

        const bodyFormatted = collectedResults.map((values) => {
          return filteredPDFColumns.map(
            ({ func, key }) =>
              func?.(values[key], values) || (values[key] as string)
          );
        });

        const dateRangeTitle = formattedDateRange(dateRange);

        onGeneratePDF(
          {
            head: [filteredPDFColumns.map(({ label }) => label)],
            body: bodyFormatted,
            ...onGeneratePDFDefaultType(provider ? `Channel Stats Report - ${provider.label}` :`Channel Stats Report`, dateRangeTitle)
          },
          provider ? `channel-stats-report-${provider.label}.pdf` : "channel-stats-report.pdf",
          {
            orientation: "landscape",
            compress: true
          }
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export PDF file. Please contact your admin");
      }
    },
    [dateRange, fetchParameters, userAuth.inventory.value]
  );

  return (
    <>
      <Table<ChannelStatsReportResultsV2>
        checkListFilter={{
          onChange: onChangeProviders,
          options: providers,
        }}
        columns={filteredColumns}
        data={formattedData}
        dateRange={{ onChange: onChangeDateRange, value: dateRange }}
        filterMode="server"
        isLoading={isFetching || providerList.isFetching}
        limit={pageSize}
        actionVersion="v2"
        onOffsetChange={onOffsetChange}
        // onGenerateXLS={onGenerateXLSX}
        onDownloadXLS={onGenerateXLSX}
        onDownloadPDF={onGeneratePDF}
        onGeneratePDF={onGeneratePDF}
        onPageSizeChange={onPageSizeChange}
        onPageIndexChange={onPageIndexChange}
        pageSizeOptions={pageSizeOptions}
        quickSearchFilter={{
          value: quickSearch,
          onChange: onChangeQuickSearch,
        }}
        sort={{
          column: sortBy.column,
          mode: "server",
          onSort,
          order: sortBy.order,
        }}
        total={data?.count}
        providers={providers}
        actionConfig={{
          isDownloadXLS: true,
          isProviderPrint: true,
          isPrintPDF: true,
          isEmailPDF: true,
          isEmailXLS: true,
          isDownloadPDF: true
        }}
      />
    </>
  );
};
export default ChannelStats;
