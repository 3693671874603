import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
    format,
    set,
    // parseISO
} from "date-fns";
import useGetStatistics, {
    EModules,
    EOrderingReports,
    // IAPIAdvancedReports,
    // TAPIAdvancedReportsV2,
    TAdvancedReportOrderKeys,
    TAdvancedReportResultsV2,
    getStatisticsAsync,
} from "api/useGetStatistics";

import DateRange, { IDateRangeProps } from "components/DateRange";
import { IDropdownFilterKeyPair } from "components/Table";
import {
    TActionProps,
    TGeneratePdfFunction,
    TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import {
    IRangeFilterProps,
} from "components/Table/components/CustomizeFilters/types";
import { ESortOrder, ISortState } from "components/Table/types";
import Tabs from "components/Tabs";
import Typography from "components/Typography";
import apiDateFormat from "constants/apiDateFormat";
import {
    // IDateRange,
    commonDateRangesV2
} from "constants/commonDateRanges";
import { useDebounce } from "hooks";
import styles from "pages/Reporting/styles.module.scss";
// import EDaysOnLot from "types/APIDaysOnLot";
import { IProviderCheckList } from "types/IProviderCheckList";
import { EQueryParameterKeys, ERoutesAnchors } from "types/Routes";
import {
    ELocalSavedFilters,
    ITableWithProviders,
    getDefaultFilters,
    setDefaultFilters,
    tableFiltersWithProviders,
} from "utils/defaultFilters";
import { filterColumnsV2 } from "utils/filterColumns";
import formatOrderingAPI from "utils/formatOrderingAPI";
import {
    getColumns,
    getPdfColumns,
    getXLSColumns,
    tablePDFFileNames,
    tableTabs,
} from "./constants";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import { capitalizeFirstLetter, formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import useUrlQuery from "hooks/useUrlQuery";
import { ReportingUrlParams } from "utils/urlParams";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import VDPBoostReporting from "../../../../Reporting/components/VDPBoost";
import DataTable from "components/v2/DataTable";
import { Box, Text } from "@chakra-ui/react";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { CustomizeProvider } from "components/v2/CustomizeProvider";
import { PaginationState, SortingState } from "@tanstack/react-table";
import DolSelect from "components/v2/Select/DolSelect";

const ReportingTabs = () => {

    // const dispatch = useAppDispatch();

    // const reportingTab = useAppSelector(selectReportingTabData);

    const { getParam, getMultipleParams, setMultipleParams, removeMultipleParams } = useUrlQuery<ReportingUrlParams>();

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");
    const dolRangeParams = getParam("dolRange");
    const sourceParams = getMultipleParams("source");
    const sortByParams = getParam("sortBy");
    const searchParams = getParam("search");
    const selectedDolRangeParams = getParam("selectedDolRange");
    const activeTableParams = getParam("activeTable");
    const leadsZeroParams = getParam("leadsZero");

    // useEffect(() => {
    //   if (activeTableParams) {
    //   dispatch(setActiveTab(activeTableParams as EOrderingReports));
    //   }
    // }, []);

    const currentTableFilter = useMemo((): ELocalSavedFilters | null => {
        switch (activeTableParams as EOrderingReports) {
            case EOrderingReports.DaysOnLot:
                return ELocalSavedFilters.DaysOnLotTable;

            case EOrderingReports.LeastVDPs:
                return ELocalSavedFilters.LeastVDPTable;

            case EOrderingReports.MostVDPs:
                return ELocalSavedFilters.MostVDPTable;

            case EOrderingReports.MostLeads:
                return ELocalSavedFilters.MostLeadsTable;
            case EOrderingReports.VDPBoost:
                return ELocalSavedFilters.VDPBoostTable;
            default:
                return null;
        }
    }, [activeTableParams]);

    const defaultFilters = useMemo(() => {
        if (currentTableFilter) {
            return getDefaultFilters<ITableWithProviders>(
                currentTableFilter,
                tableFiltersWithProviders
            );
        }

        return tableFiltersWithProviders;
    }, [currentTableFilter]);

    const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
        startDateParams && endDateParams ? {
            startDate: new Date(startDateParams),
            endDate: new Date(endDateParams),
        } : commonDateRangesV2[2].value
    );

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);
    const [providers, setProviders] = useState<IProviderCheckList[]>(
        defaultFilters.providers
    );

    const dolOptions: IDropdownFilterKeyPair[] = useMemo(() => [
        {
            label: 'None',
            value: '0',
        },
        {
            label: 'Over 7',
            value: '7',
        },
        {
            label: 'Over 15',
            value: '15',
        },
        {
            label: 'Over 30',
            value: '30',
        },
        {
            label: 'Over 60',
            value: '60',
        },
    ], []);

    const findOption = selectedDolRangeParams ? dolOptions.find((option) => option.value === parseInt(selectedDolRangeParams)) : dolOptions[0];

    const [selectedDol, setSelectedDol] = useState<IDropdownFilterKeyPair>(findOption ?? dolOptions[0]);

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", ""),
        desc: sortByParams.startsWith("-") ? true : false,
    }] : []);

    const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");

    const [dolRange, setDolRange] = useState<IRangeFilterProps["value"]>(
        dolRangeParams
            ? (dolRangeParams.split("-").map(Number) as [number, number])
            : [1, 2000]
    );

    const quickSearchApi = useDebounce<string>(quickSearch, 350);

    const userAuth = useAppSelector(selectUserData);

    // get 3rd party providers
    const fetchProvders = useMemo(() => ({
        module: EProvidersModules.ThirdPartyProviderV2,
        params: {
            dealership_uid: userAuth.dealerV2.uid,
            inventory: userAuth.inventory.value
        }
    }), [userAuth.dealerV2.uid, userAuth.inventory.value]);

    const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);

    // set providers
    useMemo(() => {
        if (!providerList.isFetching && providerList.data) {
            const tempProviders: IProviderCheckList[] = ((providerList.data as any).results as any[]).map((provider) => {
                return {
                    id: provider.value,
                    label: provider.label,
                    value: sourceParams?.includes(provider.value.toString()) || false,
                }
            });
            setProviders(tempProviders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerList.data, providerList.isFetching]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: null,
                order: ESortOrder.None,
            };
        }
        return {
            column: sortBy[0].id as TAdvancedReportOrderKeys,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
            module: EModules.AdvancedReportsV2,
            params: {
                ...(selectedDol.value !== 0 && {
                    dol_max: 2000,
                    dol_min: selectedDol.value,
                }),
                ...((activeTableParams === EOrderingReports.DaysOnLot ||
                    activeTableParams === EOrderingReports.MostLeads) &&
                    selectedDol.value === 0 && {
                    dol_max: dolRangeParams
                        ? isNaN(dolRangeParams.split("-").map(Number)[1])
                            ? 2000
                            : dolRangeParams.split("-").map(Number)[1]
                        : 2000,
                    dol_min: dolRangeParams
                        ? dolRangeParams.split("-").map(Number)[0]
                        : 0,
                }),
                date_from: format(dateRange.startDate, apiDateFormat),
                date_to: format(dateRange.endDate, apiDateFormat),
                // dol_range: daysOnLot,
                dealership_uid: userAuth.dealerV2.uid,
                page: page + 1,
                // limit: pageSize,
                // offset,
                page_size: pageSize,
                order: activeTableParams,
                ordering: formatOrderingAPI<TAdvancedReportOrderKeys>(sortedBy),
                search: quickSearchApi,
                source: providers.filter(({ value }) => value).map(({ id }) => id),
                ...(leadsZeroParams !== null && {
                    leads_zero: leadsZeroParams,
                }),
                inventory: userAuth.inventory.value,
            },
        }),
        [selectedDol.value, activeTableParams, dolRangeParams, dateRange.startDate, dateRange.endDate, userAuth.dealerV2.uid, userAuth.inventory.value, page, pageSize, sortedBy, quickSearchApi, providers, leadsZeroParams]
    );

    const { data, isFetching } = useGetStatistics<EModules.AdvancedReportsV2>(fetchParameters);

    useEffect(() => {
        // update url

        console.log('activeTableParams', activeTableParams, selectedDol.value);

        setMultipleParams({
            startDate: format(dateRange.startDate, apiDateFormat),
            endDate: format(dateRange.endDate, apiDateFormat),
            activeTable: activeTableParams ?? undefined,
            // ...(quickSearchApi && {
            //   search: quickSearchApi
            // }),
            // ...(sortBy.order && sortBy.column && {
            //   sortBy: sortBy.order === ESortOrder.Ascending ? sortBy.column : `-${sortBy.column}`,
            // }),
            ...(activeTableParams === EOrderingReports.DaysOnLot && selectedDol.value === 0 && {
                dolRange: `${dolRange[0]}-${dolRange[1]}`
            }),
            // ...(leadsZeroParams && {
            leadsZero: leadsZeroParams ?? undefined,
            // }),
            // ...(activeTableParams === EOrderingReports.DaysOnLot && selectedDol.value !== 0 && {
            selectedDolRange: selectedDol ? selectedDol.value.toString() : undefined,
            // })
            inventory: userAuth.inventory.value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.startDate, dateRange.endDate, activeTableParams, selectedDol, dolRange, leadsZeroParams, userAuth.inventory.value]);

    const onChangerOrder = useCallback((newTabIndex: number, newOrder?: string | number) => {

        setDateRange(newOrder === EOrderingReports.DaysOnLot ? commonDateRangesV2[2].value : commonDateRangesV2[2].value);

        console.log('newOrder', newOrder);

        // dispatch(setActiveTab(newOrder as EOrderingReports));

        removeMultipleParams(['sortBy', 'selectedDolRange']);

        setSelectedDol(dolOptions[0]);

        setDolRange([1, 2000]);

        setMultipleParams({
            [EQueryParameterKeys.ActiveTable]: newOrder as EOrderingReports,
        });

        setSortBy([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = (search: string) => {
        setQuickSearch(search);
        setMultipleParams({
            search
        });
    };

    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
        setMultipleParams({
            sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
        });
    }, []);

    const activeIndexQuickOption = useMemo(
        () =>
            commonDateRangesV2.findIndex(
                ({ value: quickOptionValue }) =>
                    format(quickOptionValue.startDate, apiDateFormat) ===
                    format(dateRange.startDate, apiDateFormat) &&
                    format(quickOptionValue.endDate, apiDateFormat) ===
                    format(dateRange.endDate, apiDateFormat)
            ),
        [dateRange.endDate, dateRange.startDate]
    );

    const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading) => {
            setIsLoading(true);

            try {
                let hasNextPage = true;
                let collectedResults: TAdvancedReportResultsV2[] = [];
                // let mapOffset = defaultOffset;

                let tempPage = 1;

                do {
                    const { next, results } = await getStatisticsAsync<EModules.AdvancedReportsV2>({
                        ...fetchParameters,
                        params: {
                            ...fetchParameters.params,
                            page: tempPage,
                            page_size: 1000
                            // offset: mapOffset,
                        },
                    })();

                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    // console.log('next', next);

                    hasNextPage = next ? true : false;
                    // mapOffset = mapOffset + defaultPageSize;
                } while (hasNextPage);


                // filter providers
                const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
                    // cgr_deal_rating
                    // atc_deal_rating
                    // ccm_deal_rating
                    return providers.id.toLowerCase();
                });

                // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
                const filteredPDFColumns = getPdfColumns(userAuth.inventory.value).filter(({ key }) => {
                    if (key.includes('_deal_rating')) {
                        return filteredProviders.includes(key.split('_deal_rating')[0]);
                    }
                    return true;
                });

                const bodyFormatted = collectedResults.map((values) => {
                    return filteredPDFColumns.map(
                        ({ func, key }) =>
                            func?.(values[key], values) || (values[key] as string)
                    );
                });

                const dateRangeTitle = activeIndexQuickOption !== -1 ? `${commonDateRangesV2[activeIndexQuickOption].label} (${formattedDateRange(dateRange)})` : formattedDateRange(dateRange);

                const tablName = tableTabs.find((tableTab) => tableTab.id === activeTableParams)!.label;

                onGeneratePDF(
                    {
                        head: [filteredPDFColumns.map(({ label }) => label)],
                        body: bodyFormatted,
                        ...onGeneratePDFDefaultType(`${capitalizeFirstLetter(tablName)}`, dateRangeTitle)
                    },
                    `${tablePDFFileNames[activeTableParams as EOrderingReports]}.pdf`,
                    {
                        orientation: "landscape",
                        compress: true
                    }
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export PDF file. Please contact your admin");
            }
        },
        [providers, userAuth.inventory.value, activeIndexQuickOption, dateRange, activeTableParams, fetchParameters]
    );

    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
            setIsLoading(true);

            try {
                let hasNextPage = true;
                let collectedResults: TAdvancedReportResultsV2[] = [];
                // let mapOffset = defaultOffset;

                let tempPage = 1;

                do {
                    const { next, results } = await getStatisticsAsync<EModules.AdvancedReportsV2>({
                        ...fetchParameters,
                        params: {
                            ...fetchParameters.params,
                            page: tempPage,
                            page_size: 1000
                            // offset: mapOffset,
                        },
                    })();

                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    // console.log('next', next);

                    hasNextPage = next ? true : false;
                    // mapOffset = mapOffset + defaultPageSize;
                } while (hasNextPage);


                // filter providers
                const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
                    return providers.id.toLowerCase();
                });

                // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
                const filteredXLSColumns = getXLSColumns(userAuth.inventory.value).filter(({ key }) => {
                    if (key.includes('_deal_rating')) {
                        return filteredProviders.includes(key.split('_deal_rating')[0]);
                    }
                    return true;
                });

                const bodyFormatted = collectedResults.map((values) => {
                    return filteredXLSColumns.map(
                        ({ func, key }) =>
                            func?.(values[key], values) || (values[key] as string)
                    );
                });

                generateXLS(
                    {
                        head: [filteredXLSColumns.map(({ label }) => label)],
                        body: bodyFormatted,
                    },
                    `${tablePDFFileNames[activeTableParams as EOrderingReports]}.xls`,
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export XLS file. Please contact your admin");
            }
        },
        [providers, userAuth.inventory.value, activeTableParams, fetchParameters]
    );

    const filteredColumns = useMemo(
        () => filterColumnsV2(getColumns(userAuth.inventory.value), providers),
        [providers, userAuth.inventory.value]
    );

    const saveSelectedFilters = useCallback(() => {
        if (currentTableFilter) {
            setDefaultFilters<ITableWithProviders>(currentTableFilter, {
                dateRange,
                pageSize,
                providers,
            });
        }
    }, [currentTableFilter, dateRange, pageSize, providers]);

    useEffect(() => {
        saveSelectedFilters();
    }, [dateRange, pageSize, activeTableParams, providers, saveSelectedFilters]);

    const onPageChange = useCallback((pagination: PaginationState) => {
        setPage(pagination.pageIndex);
        setPageSize(pagination.pageSize);
    }, []);

    const onDateChange = (newValue: [Date, Date]) => {
        // setDateRange({ startDate, endDate });
        setDateRange({
            startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
            endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
        });
    };

    const onProviderChange = (selectedProvider: IProviderCheckList) => {
        setProviders((prevProviders) =>
            prevProviders.map(provider =>
                provider.id === selectedProvider.id ? { ...provider, value: !provider.value } : provider
            )
        );

    };

    const onDolChange = (selectedDol: IDropdownFilterKeyPair) => {
        setSelectedDol(selectedDol);
        // setSortBy([{ id: 'dol', desc: false }]);
    };

    const getActiveTable = tableTabs.findIndex((tableTab) => tableTab.id === activeTableParams);

    const columnSidebarChilrend = () => {
        if (!providers.length || userAuth.inventory.value === 'new') return null;
        return (
            <Box
                display="flex"
                flexFlow="column"
                gap="10px"
            >
                <Text
                    fontWeight="600"
                >
                    Providers
                </Text>
                <CustomizeProvider
                    providers={providers}
                    onChange={onProviderChange}
                />
            </Box>
        );
    };

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap="15px"
                    flex="1"
                >
                    {activeTableParams === EOrderingReports.DaysOnLot && (
                        <DolSelect
                            defaultValue={selectedDol}
                            options={dolOptions}
                            onChange={onDolChange}
                        />
                    )}
                </Box>
                <Box>
                    <ActionButtonV2
                        actionConfig={{
                            isDownloadPDF: true,
                            isDownloadXLS: true,
                            isEmailPDF: true,
                            isEmailXLS: true,
                            isPrintPDF: true,
                        }}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <div id={ERoutesAnchors.ReportingTables} className={styles.reporting}>
            <Typography
                className={styles["reporting__tableLabel"]}
                fontSize="text-xl"
                fontWeight="bold"
            >
                Advanced Report
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                gap="15px"
            >
                <Tabs
                    active={getActiveTable}
                    tabs={tableTabs}
                    onClickTab={onChangerOrder}
                />
                {activeTableParams !== EOrderingReports.VDPBoost && (

                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="15px"
                    >
                        <DateRange
                            onChange={onDateChange}
                            quickOptions={commonDateRangesV2}
                            value={dateRange}
                        />
                        <DataTable<TAdvancedReportResultsV2>
                            data={data?.results || []}
                            rowCount={data?.count || 0}
                            onPageChange={onPageChange}
                            sort={sortBy}
                            onSort={onSort}
                            columns={filteredColumns as any}
                            pageIndex={page}
                            pageSize={pageSize}
                            isLoading={isFetching}
                            conttroller={conttroller()}
                            search={quickSearchApi}
                            onSearch={onSearch}
                            pageCount={data?.total_pages}
                            // onColumnVisibilityChange={setColumnVisibility}
                            // columnVisibility={columnVisibility}
                            // onColumnSizingChange={setColumnSizing}
                            // columnSizing={columnSizing}
                            showColumns={true}
                            columnsSidebarChildren={columnSidebarChilrend()}
                        // onUpdateData={updateData}
                        />
                    </Box>
                )}
                {activeTableParams === EOrderingReports.VDPBoost && (
                    <VDPBoostReporting />
                )}
            </Box>
        </div>
    );
};

export default ReportingTabs;
