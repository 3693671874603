import { createColumnHelper } from "@tanstack/react-table";
import {
  EAPIProviderRanks,
  EAPIProviderRanksReverse,
  EProviderInitials,
} from "types/APIProviderRanks";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import formatCellVehicle from "utils/formatCellVehicle";
import ResultScoreColorBadge from "./components/ResultsScoreBadge";
import styles from "./styles.module.scss";
import { getResultScoreColorNameV2 } from "./components/ResultsScoreBadge/utils";
import getProviderRank from "utils/getProviderRank";
import Badge from "components/Badge";
import { IAPIRecentActivityReportV2 } from "api/useGetReports";
import { TInventoryOptionValue } from "constants/common";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";

export const columnHelper = createColumnHelper<IAPIRecentActivityReportV2>();

export const getColumns = (inventory: TInventoryOptionValue) => (
  [
    columnHelper.accessor((row) => row.result_score, {
      id: "result_score",
      cell: (info) => <ResultScoreColorBadge value={info.getValue()} />,
      header: () => (
        <span className={styles.vdpResults__resultScoreHeader}>
          Results Score
        </span>
      ),
      size: 65,
    }),
    columnHelper.accessor((row) => row.stock_number, {
      id: "stock_number",
      cell: (info) => info.getValue(),
      header: () => (
        <span className={styles.vdpResults__stockNumberHeader}>Stock #</span>
      ),
      size: 93,
    }),
    columnHelper.accessor(formatCellVehicle, {
      id: "year",
      cell: (info) => info.getValue(),
      header: () => (
        <span className={styles.vdpResults__vehicleHeader}>Vehicle</span>
      ),
      size: 180,
    }),
    ...(inventory === 'used' ? [
      {
        classNameHeader: styles.vdpResults__cargurusHeader,
        filterColumnId: EProviderInitials.CarGurus,
        ...columnHelper.accessor('cgr_deal_rating',
          {
            id: "cgr_deal_rating",
            cell: (info) => {
              const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
              return <Badge color={color} icon={icon} label={label} size="sm" />;
            },
            header: () => <span>CarGurus Rank</span>,
            size: 95,
          }
        ),
      },
      {
        classNameHeader: styles.vdpResults__autotraderHeader,
        filterColumnId: EProviderInitials.Autotrader,
        ...columnHelper.accessor('atc_deal_rating',
          {
            id: "atc_deal_rating",
            cell: (info) => {
              const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
              return <Badge color={color} icon={icon} label={label} size="sm" />;
            },
            header: () => (
              <span className={styles.vdpResults__autotraderRankHeader}>
                AutoTrader Rank
              </span>
            ),
            size: 95,
          }
        ),
      },
      {
        classNameHeader: styles.vdpResults__carsDotComHeader,
        filterColumnId: EProviderInitials.CarsDotCom,
        ...columnHelper.accessor('ccm_deal_rating',
          {
            id: "ccm_deal_rating",
            cell: (info) => {
              const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
              return <Badge color={color} icon={icon} label={label} size="sm" />;
            },
            header: () => (
              <span className={styles.vdpResults__carsDotComRankHeader}>
                Cars.com Rank
              </span>
            ),
            size: 95,
          }
        ),
      },
      {
        classNameHeader: styles.vdpResults__carsDotComHeader,
        filterColumnId: EProviderInitials.Carfax,
        ...columnHelper.accessor('cfx_deal_rating',
          {
            id: "cfx_deal_rating",
            cell: (info) => {
              const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
              return <Badge color={color} icon={icon} label={label} size="sm" />;
            },
            header: () => (
              <span className={styles.vdpResults__carsDotComRankHeader}>
                Carfax Rank
              </span>
            ),
            size: 95,
          }
        ),
      },
    ] : []),
    {
      classNameHeader: styles.vdpResults__greenHeader,
      ...columnHelper.accessor('vdps', {
        id: "vdps",
        cell: (info) => info.getValue() || 0,
        header: () => <span>VDPs</span>,
        size: 80,
      }),
    },
    {
      classNameHeader: styles.vdpResults__greenHeader,
      ...columnHelper.accessor('leads', {
        id: "leads",
        cell: (info) => info.getValue() || 0,
        header: () => <span>Leads</span>,
        size: 83,
      }),
    },
    {
      classNameHeader: styles.vdpResults__greenHeader,
      ...columnHelper.accessor('leads_vdps', {
        id: "leads_vdps",
        cell: (info) => info.getValue(),
        header: () => <span>Leads/VDP</span>,
        size: 113,
      }),
    },
    columnHelper.accessor('price', {
      id: "price",
      cell: (info) => `$${Number(info.getValue()).toLocaleString()}`,
      header: () => <span>Price</span>,
      size: 92,
    }),
    columnHelper.accessor('last_price_change', {
      id: "last_price_change",
      cell: (info) => (info.getValue() === null ? "N/A" : info.getValue()),
      header: () => (
        <span className={styles.vdpResults__lastPriceChangeHeader}>
          Last Price Change
        </span>
      ),
      size: 120,
    }),
    ...(inventory === 'used' ? [
      columnHelper.accessor("default_percentage_to_market", {
        cell: (info) =>
          info.getValue() === null
            ? "N/A"
            : `${(info.getValue() * 100).toLocaleString()}%`,
        header: () => (
          <span className={styles.vdpResults__defaultPercentageMarketHeader}>
            Default % of Market
          </span>
        ),
        id: "default_percentage_to_market",
        size: 92,
      }),
    ] : []),
    ...(inventory === 'new' ? [
      columnHelper.accessor("price_to_market", {
        cell: (info) =>
          info.getValue() === null
            ? "N/A"
            : `${(info.getValue() * 100).toLocaleString()}%`,
        header: () => (
          <span className={styles.vdpResults__defaultPercentageMarketHeader}>
            Price to Market
          </span>
        ),
        id: "percentage_to_market",
        size: 117,
      }),
    ] : []),
    columnHelper.accessor('dol', {
      id: "dol",
      cell: (info) => info.getValue(),
      header: () => <span>DOL</span>,
      size: 70,
    }),
    columnHelper.accessor('photos_count', {
      id: "photos_count",
      cell: (info) => info.getValue(),
      header: () => <span>Photos</span>,
      size: 70,
    }),
    ...(inventory === 'used' ? [
      columnHelper.accessor('total_carfax_pull', {
        id: "total_carfax_pull",
        cell: (info) => info.getValue(),
        size: 70,
        header: () => <span>CarFax Pulls</span>,
      }),
    ] : [])
  ]
);

export const getPDFColumns = (inventory: TInventoryOptionValue): TAggregatorColumnPDF<IAPIRecentActivityReportV2>[] => ([
  {
    label: "Result Score",
    key: "result_score",
    func: (value) =>
      getResultScoreColorNameV2(value as number).toLocaleUpperCase(),
  },
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "model",
    func: (value, row) => row ? formatCellVehicle(row) : '',
  },
  ...(inventory === 'used' ? [
    {
      label: "CarGurus Rank",
      key: "cgr_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    },
    {
      label: "Autotrader Rank",
      key: "atc_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    },
    {
      label: "Cars.com Rank",
      key: "ccm_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    },
    {
      label: "Carfax Rank",
      key: "cfx_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    }
  ] : []) as TAggregatorColumnPDF<IAPIRecentActivityReportV2>[],
  {
    label: "VDPs",
    key: "vdps",
  },
  {
    label: "Leads",
    key: "leads",
  },
  {
    label: "Leads/VDP",
    key: "leads_vdps",
  },
  {
    label: "Price",
    key: "price",
    func: (value) => `$${Number(value).toLocaleString()}`,
  },
  {
    label: "Last Price Change",
    key: "last_price_change",
    func: (value) => (value === null ? "N/A" : (value as string)),
  },
  ...(inventory === 'used' ? [
    {
      label: "Default % of Market",
      key: "default_percentage_to_market",
      func: (value) =>
        value === null
          ? "N/A"
          : `${(((value as number) || 0) * 100).toLocaleString()}%`,
    },
  ] : []) as TAggregatorColumnPDF<IAPIRecentActivityReportV2>[],
  ...(inventory === 'new' ? [
    {
      label: "Price Market",
      key: "price_to_market",
      func: (value) =>
        value === null
          ? "N/A"
          : `${(((value as number) || 0) * 100).toLocaleString()}%`,
    },
  ] : []) as TAggregatorColumnPDF<IAPIRecentActivityReportV2>[],
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "Photos",
    key: "photos_count",
  },
  ...(inventory === 'used' ? [
    {
      label: "CarFax Pulls",
      key: "total_carfax_pull",
    },
  ] : []) as TAggregatorColumnPDF<IAPIRecentActivityReportV2>[],
]);

// pdf
export const getXlsColumns = (inventory: TInventoryOptionValue): TAggregatorColumnXLS<IAPIRecentActivityReportV2>[] => ([
  {
    label: "Result Score",
    key: "result_score",
    func: (value) =>
      getResultScoreColorNameV2(value as number).toLocaleUpperCase(),
  },
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "model",
    func: (value, row) => formatCellVehicle(row),
  },
  ...(inventory === 'used' ? [
    {
      label: "CarGurus Rank",
      key: "cgr_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    },
    {
      label: "Autotrader Rank",
      key: "atc_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    },
    {
      label: "Cars.com Rank",
      key: "ccm_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    },
    {
      label: "Carfax Rank",
      key: "cfx_deal_rating",
      func(value) {
        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
      }
    }
  ] : []) as TAggregatorColumnXLS<IAPIRecentActivityReportV2>[],
  {
    label: "VDPs",
    key: "vdps",
  },
  {
    label: "Leads",
    key: "leads",
  },
  {
    label: "Leads/VDP",
    key: "leads_vdps",
  },
  {
    label: "Price",
    key: "price",
    func: (value) => `$${Number(value).toLocaleString()}`,
  },
  {
    label: "Last Price Change",
    key: "last_price_change",
    func: (value) => (value === null ? "N/A" : (value as string)),
  },
  ...(inventory === 'used' ? [
    {
      label: "Default % of Market",
      key: "default_percentage_to_market",
      func: (value) =>
        value === null
          ? "N/A"
          : `${(((value as number) || 0) * 100).toLocaleString()}%`,
    },
  ] : []) as TAggregatorColumnXLS<IAPIRecentActivityReportV2>[],
  ...(inventory === 'new' ? [
    {
      label: "Price Market",
      key: "price_to_market",
      func: (value) =>
        value === null
          ? "N/A"
          : `${(((value as number) || 0) * 100).toLocaleString()}%`,
    },
  ] : []) as TAggregatorColumnXLS<IAPIRecentActivityReportV2>[],
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "Photos",
    key: "photos_count",
  },
  ...(inventory === 'used' ? [
    {
      label: "CarFax Pulls",
      key: "total_carfax_pull",
    },
  ] : []) as TAggregatorColumnXLS<IAPIRecentActivityReportV2>[],
]);
