import { createColumnHelper } from "@tanstack/react-table";
import { VDPROIResultV2 } from "api/useGetSales";
import { TInventoryOptionValue } from "constants/common";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import { capitalizeFirstLetter } from "utils/helper";

const columnHelper = createColumnHelper<VDPROIResultV2>();

export const getColumns = (inventory: TInventoryOptionValue) => [
    columnHelper.accessor((row) => row, {
        id: "rank",
        cell: (info) => info.row.index + 1,
        enableSorting: true,
        header: () => (
          <span>
            Contribution Rank:
            <span style={{ color: 'gray', paddingLeft: '3px' }}>{capitalizeFirstLetter(inventory)}</span>
          </span>
        ),
    }),
    columnHelper.accessor((row) => row.source, {
        id: "source",
        cell: (info) => info.getValue(),
        enableSorting: true,
        header: () => (
          <span>
            Source:
            <span style={{ color: 'gray', paddingLeft: '3px' }}>{capitalizeFirstLetter(inventory)}</span>
          </span>
        ),
    }),
    columnHelper.accessor((row) => row.total_vdps, {
        id: "total_vdps",
        enableSorting: true,
        cell: (info) => (info.getValue() || 0).toLocaleString(),
        header: () => (
          <span>
            Lifetime VDPs:
            <span style={{ color: 'gray', paddingLeft: '3px' }}>{capitalizeFirstLetter(inventory)}</span>
          </span>
        ),
    }),
    // columnHelper.accessor((row) => row.sold, {
    //       id: "sold",
    //       enableSorting: true,
    //       cell: (info) => (info.getValue() || 0).toLocaleString(),
    //       header: () => <span>Sold</span>,
    // }),
    // columnHelper.accessor((row) => row.srp_vdp_ratio, {
    //     id: "srp_vdp_ratio",
    //     enableSorting: true,
    //     cell: (info) => `${(info.getValue() || 0).toLocaleString()}%`,
    //     header: () => <span>SRP/VDP Ratio</span>,
    // }),
];

export const getPdfColumns = (inventory: TInventoryOptionValue): TAggregatorColumnPDF<VDPROIResultV2>[] => ([
    {
      // label: "Contribution Rank",
      label: `Contribution Rank: ${capitalizeFirstLetter(inventory)}`,
      key: "rank",
    },
    {
      // label: "Source",
      label: `Source: ${capitalizeFirstLetter(inventory)}`,
      key: "source",
    },
    {
      // label: "Total VDPs",
      label: `Total VDPs: ${capitalizeFirstLetter(inventory)}`,
      key: "total_vdps",
    },
    // {
      // label: "Sold",
    // label: `Sold: ${capitalizeFirstLetter(inventory)}`,
    //   key: "sold",
    // },
    // {
      // label: "SRP/VDP Ratio",
    // label: `SRP/VDP Ratio: ${capitalizeFirstLetter(inventory)}`,
    //   key: "srp_vdp_ratio",
    // },
  ]);

  export const getXLSColumns = (inventory: TInventoryOptionValue): TAggregatorColumnXLS<VDPROIResultV2>[] => ([
    {
      // label: "Contribution Rank",
      label: `Contribution Rank: ${capitalizeFirstLetter(inventory)}`,
      key: "rank",
    },
    {
      // label: "Source",
      label: `Source: ${capitalizeFirstLetter(inventory)}`,
      key: "source",
    },
    {
      // label: "Total VDPs",
      label: `Total VDPs: ${capitalizeFirstLetter(inventory)}`,
      key: "total_vdps",
    },
    // {
    //   label: "Sold",
    // label: `Sold: ${capitalizeFirstLetter(inventory)}`,
    //   key: "sold",
    // },
    // {
    //   label: "SRP/VDP Ratio",
    // label: `SRP/VDP Ratio: ${capitalizeFirstLetter(inventory)}`,
    //   key: "srp_vdp_ratio",
    // },
  ]);
