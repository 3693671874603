import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useGetSales, {
  EModules,
  IAPIDealRatingsV2,
  TDealRatingOrderKeys,
  getSalesAsync,
} from "api/useGetSales";
import Table from "components/Table";
import { ESortOrder, ISortState, TSort } from "components/Table/types";
import { ICheckListFilterProps } from "components/Table/components/CustomizeFilters/types";
import {
  TActionProps,
  TGeneratePdfFunction,
  TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import { IDateRangeProps } from "components/DateRange";
import apiDateFormat from "constants/apiDateFormat";
import { useDebounce } from "hooks";
import { pageSizeOptions } from "constants/defaultPageSizes";
import { IProviderCheckList } from "types/IProviderCheckList";
import {
  ELocalSavedFilters,
  ISavedDefaultFilters,
  getDefaultFilters,
  setDefaultFilters,
  tableFiltersWithProviders,
} from "utils/defaultFilters";
import { filterColumnsV2 } from "utils/filterColumns";
import formatOrderingAPI from "utils/formatOrderingAPI";
import { columns, pdfColumns, xlsColumns } from "./constants";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import commonDateRanges from "constants/commonDateRanges";
import { useUrlQuery } from "hooks/useUrlQuery";
import { DealRatingsUrlParams } from "utils/urlParams";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

type TSavedFilters = ISavedDefaultFilters[ELocalSavedFilters.DealRatingsTable];

const DealRatings = () => {

  const userAuth = useAppSelector(selectUserData);

  const { getParam, getMultipleParams, setMultipleParams } = useUrlQuery<DealRatingsUrlParams>();

  const startDateParams = getParam("startDate");
  const endDateParams = getParam("endDate");
  const sourceParams = getMultipleParams("source");
  const sortByParams = getParam("sortBy");
  const searchParams = getParam("search");

  const defaultFilters = getDefaultFilters<TSavedFilters>(
    ELocalSavedFilters.DealRatingsTable,
    tableFiltersWithProviders
  );
  const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
    {
      startDate: startDateParams ? new Date(startDateParams) : defaultFilters.dateRange.startDate,
      endDate: endDateParams ? new Date(endDateParams) : defaultFilters.dateRange.endDate,
    }
  );

  // const [offset, setOffset] = useState<number>(defaultOffset);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);
  const [providers, setProviders] = useState<IProviderCheckList[]>(
    defaultFilters.providers
  );
  const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");

  const quickSearchApi = useDebounce<string>(quickSearch, 350);

  const [sortBy, setSortBy] = useState<ISortState>(sortByParams && typeof sortByParams === 'string' ?
    {
      column: sortByParams.replace("-", "") as TDealRatingOrderKeys,
      order: sortByParams.startsWith("-") ? ESortOrder.Descending : ESortOrder.Ascending,
    }
    : {
    column: columns[0].id as TDealRatingOrderKeys,
    order: ESortOrder.Ascending,
  });


  useEffect(() => {

    const selectedProviders = providers.filter(({ value }) => value).map(({ id }) => id);

    setMultipleParams({
      startDate: format(dateRange.startDate, apiDateFormat),
      endDate: format(dateRange.endDate, apiDateFormat),
      source: selectedProviders,
      inventory: userAuth.inventory.value,
      ...(sortBy.order && sortBy.column && {
        sortBy: sortBy.order === ESortOrder.Ascending ? sortBy.column : `-${sortBy.column}`,
      }),
      search: quickSearchApi
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.endDate, dateRange.startDate, quickSearchApi, sortBy.column, sortBy.order, userAuth.inventory.value]);

  // get 3rd party providers
  const fetchProvders = useMemo(() => ({
    module: EProvidersModules.ThirdPartyProviderV2,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      inventory: userAuth.inventory.value
    }
  }), [userAuth.dealerV2.uid, userAuth.inventory.value]);

  const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);

  // set providers
  useMemo(() => {
    if (!providerList.isFetching && providerList.data) {
      const tempProviders = ((providerList.data as any).results as any[]).map((provider) => {
        return {
          id: provider.value,
          label: provider.label,
          value: sourceParams?.includes(provider.value.toString()) || false,
        }
      });
      setProviders(tempProviders);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerList.data, providerList.isFetching]);

  const fetchParameters = useMemo(
    () => ({
      module: EModules.DealRatingsV2,
      params: {
        date_from: format(dateRange.startDate, apiDateFormat),
        date_to: format(dateRange.endDate, apiDateFormat),
        dealership_uid: userAuth.dealerV2.uid,
        page: page + 1,
        page_size: pageSize,
        // limit: pageSize,
        // offset,
        ordering: formatOrderingAPI<TDealRatingOrderKeys>(sortBy),
        search: quickSearchApi,
        source: providers.filter(({ value }) => value).map(({ id }) => id),
        inventory: userAuth.inventory.value
      },
    }),
    [dateRange.endDate, dateRange.startDate, page, pageSize, providers, quickSearchApi, sortBy, userAuth.dealerV2.uid, userAuth.inventory.value]
  );
  const { data, isFetching } =
    useGetSales<EModules.DealRatingsV2>(fetchParameters);

  const onChangeDateRange: IDateRangeProps["onChange"] = useCallback(
    ([startDate, endDate]) => {
      setDateRange({ startDate, endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  const onOffsetChange = useCallback((newOffset: number) => {
    // setOffset(newOffset);
  }, []);

  const onPageIndexChange = useCallback((newPageIndex: number) => {
    setPage(newPageIndex);
  }, []);

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const activeIndexQuickOption = useMemo(
    () =>
      commonDateRanges.findIndex(
        ({ value: quickOptionValue }) =>
          format(quickOptionValue.startDate, apiDateFormat) ===
            format(dateRange.startDate, apiDateFormat) &&
          format(quickOptionValue.endDate, apiDateFormat) ===
            format(dateRange.endDate, apiDateFormat)
      ),
    [dateRange.endDate, dateRange.startDate]
  );

  const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
    async (onGeneratePDF, setIsLoading) => {
      setIsLoading(true);

      try {
        let hasNextPage = true;
        let collectedResults: IAPIDealRatingsV2[] = [];
        // let mapOffset = 0;

        let tempPage = 1;
        do {
          const { next, results } = await getSalesAsync<EModules.DealRatingsV2>({
            ...fetchParameters,
            params: {
              ...fetchParameters.params,
              page: tempPage
              // limit: pageSize,
              // offset: mapOffset
            },
          })();
          collectedResults = [...collectedResults, ...results];

          tempPage++;

          hasNextPage = !!next;
          // mapOffset = mapOffset + pageSize;
        } while (hasNextPage);

        // filter providers
        const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
          // cgr_deal_rating
          // atc_deal_rating
          // ccm_deal_rating
          return providers.id.toLowerCase();
        });;


        // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
        const filteredPDFColumns = pdfColumns.filter(({ key }) => {
          if (key.includes('_deal_rating')) {
            return filteredProviders.includes(key.split('_deal_rating')[0]);
          }
          return true;
        });

        const bodyFormatted = collectedResults.map((values) => {
          return filteredPDFColumns.map(
            ({ func, key }) =>
              func?.(values[key], values) || (values[key] as string)
          );
        });

        const dateRangeTitle = activeIndexQuickOption !== -1 ? `${commonDateRanges[activeIndexQuickOption].label} (${formattedDateRange(dateRange)})` : formattedDateRange(dateRange);

        onGeneratePDF(
          {
            head: [filteredPDFColumns.map(({ label }) => label)],
            body: bodyFormatted,
            ...onGeneratePDFDefaultType(`Deal Ratings by Channel`, dateRangeTitle)
          },
          "deal-ratings-by-channel.pdf",
          {
            orientation: "landscape",
            compress: true
          }
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export PDF file. Please contact your admin");
      }
    },
    [providers, activeIndexQuickOption, dateRange, fetchParameters]
  );

  const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
    async (generateXLS, setIsLoading) => {
      setIsLoading(true);

      try {
        let hasNextPage = true;
        let collectedResults: IAPIDealRatingsV2[] = [];
        // let mapOffset = 0;

        let tempPage = 1;
        do {
          const { next, results } = await getSalesAsync<EModules.DealRatingsV2>({
            ...fetchParameters,
            params: {
              ...fetchParameters.params,
              page: tempPage,
              // limit: pageSize,
              // offset: mapOffset
            },
          })();
          collectedResults = [...collectedResults, ...results];

          tempPage++;

          hasNextPage = !!next;
          // mapOffset = mapOffset + pageSize;
        } while (hasNextPage);

        // filter providers
        const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
          // cgr_deal_rating
          // atc_deal_rating
          // ccm_deal_rating
          return providers.id.toLowerCase();
        });;


        // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
        const filteredXLSColumns = xlsColumns.filter(({ key }) => {
          if (key.includes('_deal_rating')) {
            return filteredProviders.includes(key.split('_deal_rating')[0]);
          }
          return true;
        });

        const bodyFormatted = collectedResults.map((values) => {
          return filteredXLSColumns.map(
            ({ func, key }) =>
              func?.(values[key], values) || (values[key] as string)
          );
        });

        generateXLS(
          {
            head: [filteredXLSColumns.map(({ label }) => label)],
            body: bodyFormatted,
          },
          "deal-ratings-by-channel.xls",
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export XLS file. Please contact your admin");
      }
    },
    [providers, fetchParameters]
  );

  const onChangeProviders = useCallback(
    (newValues: ICheckListFilterProps["options"]) => {
      setProviders(newValues as unknown as IProviderCheckList[]);
      setSortBy({ column: null, order: ESortOrder.None });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onSort: TSort["onSort"] = useCallback(
    ({ column, order }: ISortState) => {
      setSortBy({ column, order });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onChangeQuickSearch = useCallback(
    (newSearch: string) => {
      setQuickSearch(newSearch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const filteredColumns = useMemo(
    () => filterColumnsV2(columns, providers),
    [providers]
  );

  useEffect(() => {
    setDefaultFilters<TSavedFilters>(ELocalSavedFilters.DealRatingsTable, {
      dateRange,
      pageSize,
      providers,
    });
  }, [dateRange, pageSize, providers]);

  return (
    <Table<IAPIDealRatingsV2>
      checkListFilter={!providerList.isFetching ? {
        onChange: onChangeProviders,
        options: providers,
      } : undefined}
      columns={filteredColumns}
      data={data?.results || []}
      dateRange={{ onChange: onChangeDateRange, value: dateRange }}
      filterMode="server"
      isLoading={isFetching || providerList.isFetching}
      limit={pageSize}
      actionVersion="v2"
      onGeneratePDF={onGeneratePDF}
      onDownloadPDF={onGeneratePDF}
      onDownloadXLS={onGenerateXLSX}
      onOffsetChange={onOffsetChange}
      onPageIndexChange={onPageIndexChange}
      onPageSizeChange={onPageSizeChange}
      pageSizeOptions={pageSizeOptions}
      quickSearchFilter={{
        value: quickSearch,
        onChange: onChangeQuickSearch,
      }}
      sort={{
        column: sortBy.column,
        mode: "server",
        onSort,
        order: sortBy.order,
      }}
      total={data?.count}
      // providers={providers}
      actionConfig={{
        isDownloadPDF: true,
        isPrintPDF: true,
        isEmailPDF: true,
        isDownloadXLS: true,
        isEmailXLS: true,
      }}
    />
  );
};
export default DealRatings;
