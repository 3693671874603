import { FC, useMemo } from "react";
import cs from "classnames";
import styles from "./styles.module.scss";
import { getResultScoreColorNameV2 } from "./utils";

interface IResultScoreColorBadgeProps {
  value: number;
}

const ResultScoreColorBadge: FC<IResultScoreColorBadgeProps> = ({ value }) => {
  const colorPerScore = useMemo(() => getResultScoreColorNameV2(value), [value]);

  return (
    <div
      className={cs(
        styles.resultsScoreBadge,
        styles[`resultsScoreBadge--${colorPerScore}`]
      )}
    />
  );
};

export default ResultScoreColorBadge;
