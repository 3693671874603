"use client"

import {
  Cell,
  ColumnDef,
  ColumnSizingState,
  OnChangeFn,
  PaginationOptions,
  PaginationState,
  Row,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { useEffect, useState } from "react"
import { debounce } from "lodash"
import { Box, Card, Skeleton, Table, TableContainer, Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import SearchField from "../SearchField";
import ColumnSidebar, { ColumnSidebarProps } from "./ColumnSidebar";
import TablePagination from "./TablePagination";
import TableHeader from "./TableHeader";

interface CustomDataTableProps<TData> extends Pick<ColumnSidebarProps<TData>, "triggerButtonType" | "columnsSidebarChildren"> {
  search?: string,
  onPageChange?: (pagination: PaginationState) => void,
  onSearch?: (search: string) => void,
  isLoading?: boolean,
  conttroller?: React.ReactNode
  onSort?: (sorting: SortingState) => void
  manualSorting?: boolean;
  sort?: SortingState;
  onUpdateData?: (rowIndex: number, columnId: string, value: any, item?: Row<any>) => void;
  columnVisibility?: VisibilityState;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  columnSizing?: ColumnSizingState;
  onColumnSizingChange?: OnChangeFn<ColumnSizingState>
  showColumns?: boolean;
}

interface DataTableProps<TData, TValue> extends CustomDataTableProps<TData>, PaginationOptions, PaginationState {
  columns: ColumnDef<TData, TValue>[]
  data: TData[],
}

function DataTable<TData, TValue = unknown>({
  columns,
  data,
  autoResetPageIndex,
  getPaginationRowModel,
  manualPagination = true,
  manualSorting = true,
  pageCount,
  rowCount,
  pageSize,
  onPageChange,
  onSort,
  pageIndex,
  onSearch,
  search,
  isLoading,
  conttroller,
  onUpdateData,
  columnVisibility,
  onColumnVisibilityChange,
  columnSizing,
  onColumnSizingChange,
  sort,
  showColumns = false,
  columnsSidebarChildren,
  triggerButtonType
}: DataTableProps<TData, TValue>) {

  const [sorting, setSorting] = useState<SortingState>(sort || []);
  
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex,
    pageSize,
  });

  const [defaultColumnVisibility, setDefaultColumnVisibility] = useState<VisibilityState>(columnVisibility || {});

  const [defaultColumnSizing, setDefaultColumnSizing] = useState<ColumnSizingState>(columnSizing || {});

  useEffect(() => {
    onColumnVisibilityChange?.(defaultColumnVisibility);
  }, [defaultColumnVisibility, onColumnVisibilityChange]);

  useEffect(() => {
    onColumnSizingChange?.(defaultColumnSizing);
  }, [defaultColumnSizing, onColumnSizingChange]);

  useEffect(() => {
    setSorting(sort || []);
  }, [sort]);
  
  useEffect(() => {
    setPagination({
      pageIndex,
      pageSize,
    });
  }, [pageIndex, pageSize]);
  
  useEffect(() => {
    onSort?.(sorting);
  }, [onSort, sorting]);
  
  useEffect(() => {
    onPageChange?.(pagination);
  }, [onPageChange, pagination]);

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      sorting,
      columnVisibility: defaultColumnVisibility,
      columnSizing: defaultColumnSizing,
    },
    getCoreRowModel: getCoreRowModel(),
    rowCount,
    manualPagination,
    manualSorting,
    autoResetPageIndex,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getPaginationRowModel,
    getSortedRowModel: getSortedRowModel(), 
    onColumnVisibilityChange: setDefaultColumnVisibility,
    onColumnSizingChange: setDefaultColumnSizing,
    columnResizeMode: 'onChange',
    pageCount,
    enableGlobalFilter: true,
    meta: {
        updateData: onUpdateData
    }
  });

  const debouncedSearch = debounce((value: string) => {
    if (onSearch) {
      onSearch(value);
    }
  }, 500);
  
  const getBodyColumnCell = (cell: Cell<TData, unknown>) => {

    const isNumeric = cell.column.columnDef.meta?.type === 'number' || 
                      cell.column.columnDef.meta?.type === 'currency' ? true : false;

    const content = flexRender(cell.column.columnDef.cell, cell.getContext());

    // const contextText = cell.getContext().getValue() as string;

    const showTooltip = cell.column.columnDef.meta?.showTooltip === false;

    const tempHeader = cell.column.columnDef.header?.toString();

    const customLabel = () => (
      <Box
        padding="10px"
        display="flex"
        flexDirection="column"
        gap="5px"
      >
        <Box
          fontWeight={600}
        >
          {tempHeader}:
        </Box>
        <Box>{content}</Box>
      </Box>
    );

    return (
      <Td 
        key={cell.id}
        textAlign={cell.column.columnDef.meta?.type === 'boolean' ? 'center' : 'left'}
        isNumeric={isNumeric}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {!showTooltip ? (
          <Tooltip 
            hasArrow={true}
            label={customLabel()}
            placement="bottom"
            openDelay={500}
            backgroundColor="#fff"
            color="#000"
            padding="10px"
          >
            <span>
              {content}
            </span>
          </Tooltip>
        ) : content}
      </Td>
    );
  };

  const intializedTable = () => {
    if (isLoading) {
      return Array.from({ length: pageSize }, (_, index) => (
        <Tr key={index}>
          {columns.map((_, cellIndex) => (
            <Td key={cellIndex}>
              <Skeleton
                height={3}
                margin="5px 0"
              />
            </Td>
          ))}
        </Tr>
      ));
    } else {
      return (
        <>
          {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  fontSize="14px"
                >
                  {row.getVisibleCells().map((cell) => getBodyColumnCell(cell))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td 
                  colSpan={columns.length} 
                  textAlign="center"
                >
                  No results.
                </Td>
              </Tr>
            )}
        </>
      );
    }
  };

  return (
    <Box 
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box 
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexFlow="row wrap"
        gap="10px"
      >
        <SearchField 
          value={search}
          onChange={(e) => debouncedSearch(e.target.value)}
        />
        {showColumns && (
          <ColumnSidebar<TData>
            table={table}
            columnsSidebarChildren={columnsSidebarChildren}
            triggerButtonType={triggerButtonType}
          />
        )}
        {conttroller}
      </Box>
      <Box 
        display="flex"
        gap="10px"
      >
        <Card>
          <TableContainer>
            <Table 
              variant="simple"
              style={{
                tableLayout: 'fixed'
              }}
            >
              {/* header */}
              <TableHeader<TData> 
                table={table}
              />
              {/* body */}
              <Tbody>
                {intializedTable()}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
      <TablePagination<TData> 
        table={table}
        rowCount={rowCount}
      />
    </Box>
  )
}

export default DataTable