import { createColumnHelper } from "@tanstack/react-table";
import { IAPIPreShootReport } from "api/useGetSales";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
// import formatCellVehicle from "utils/formatCellVehicle";

export const columnHelper = createColumnHelper<IAPIPreShootReport>();

export const columns = [
  columnHelper.accessor((row) => row.stock_number, {
    id: "stockNumber",
    cell: (info) => info.getValue(),
    enableSorting: false,
    header: () => <span>Stock #</span>,
  }),
  columnHelper.accessor('vehicle', {
    id: "vehicle",
    cell: (info) => info.getValue(),
    header: () => <span>Vehicle</span>,
  }),
  columnHelper.accessor((row) => row.price, {
    id: "price",
    cell: (info) => info.getValue(),
    header: () => <span>Price</span>,
  }),
  columnHelper.accessor((row) => row.dol, {
    id: "dol",
    cell: (info) => info.getValue(),
    header: () => <span>DOL</span>,
  }),
  columnHelper.accessor((row) => row.photos, {
    id: "photos_count",
    cell: (info) => info.getValue(),
    header: () => <span>Images</span>,
  }),
  columnHelper.accessor((row) => row.total_vdps, {
    id: "total_vdps",
    cell: (info) => info.getValue(),
    header: () => <span>Total VDPs</span>,
  }),
];

export const pdfColumns: TAggregatorColumnPDF<IAPIPreShootReport>[] = [
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "vehicle",
    // func: (value, row) => (row ? formatCellVehicle(row) : "-"),
  },
  {
    label: "Price",
    key: "price",
    // func: (value) => `$${Number(value).toLocaleString()}`,
  },
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "Images",
    key: "photos",
  },
  {
    label: "Total VDPs",
    key: "total_vdps",
  },
];

export const xlsColumns: TAggregatorColumnXLS<IAPIPreShootReport>[] = [
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "vehicle",
    // func: (value, row) => (row ? formatCellVehicle(row) : "-"),
  },
  {
    label: "Price",
    key: "price",
    // func: (value) => `$${Number(value).toLocaleString()}`,
  },
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "Images",
    key: "photos",
  },
  {
    label: "Total VDPs",
    key: "total_vdps",
  },
];

export const defaultOffset = 0;
