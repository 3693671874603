import { createColumnHelper } from "@tanstack/react-table";
import { ChannelStatsReportResultsV2 } from "api/useGetSales";
import Badge from "components/Badge";
import SeeMoreText from "components/SeeMoreTexts/SeeMoreTexts";
import styles from "pages/Merchandising/styles.module.scss";
import getProviderRank from "utils/getProviderRank";
import { EAPIProviderRanks, EAPIProviderRanksReverse, EProviderInitials } from "types/APIProviderRanks";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";

export const defaultOffset = 0;

const columnHelper = createColumnHelper<ChannelStatsReportResultsV2>();

export const pdfColumns: TAggregatorColumnPDF<ChannelStatsReportResultsV2>[] = [
  {
    key: 'stock_number',
    label: 'Stock #',
  },
  {
    key: 'vehicle',
    label: 'Vehicle',
  },
  // {
  //   key: 'description',
  //   label: 'Description',
  // },
  {
    key: 'dol',
    label: 'DOL',
  },
  {
    key: 'photos_count',
    label: 'Photos',
  },
  {
    key: 'atc_price',
    label: 'Price',
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    key: 'atc_deal_rating',
    label: 'Deal Rating',
    filterColumnId: EProviderInitials.Autotrader,
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  {
    key: 'atc_total_srps',
    label: 'SRP',
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    key: 'atc_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    key: 'atc_srps_vdps',
    label: 'SRP/VDP Ratio',
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    key: 'cgr_price',
    label: 'Price',
    filterColumnId: EProviderInitials.CarGurus,
  },
  {
    key: 'cgr_deal_rating',
    label: 'Deal Rating',
    filterColumnId: EProviderInitials.CarGurus,
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  // {
  //   key: 'cgr_total_srps',
  //   label: 'SRP',
  //   filterColumnId: EProviderInitials.CarGurus,
  // },
  {
    key: 'cgr_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.CarGurus,
  },
  // {
  //   key: 'cgr_srps_vdps',
  //   label: 'SRP/VDP Ratio',
  //   filterColumnId: EProviderInitials.CarGurus,
  // },
  {
    key: 'cfx_price',
    label: 'Price',
    filterColumnId: EProviderInitials.Carfax,
  },
  {
    key: 'cfx_deal_rating',
    label: 'Deal Rating',
    filterColumnId: EProviderInitials.Carfax,
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  {
    key: 'cfx_total_srps',
    label: 'SRP',
    filterColumnId: EProviderInitials.Carfax,
  },
  {
    key: 'cfx_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.Carfax,
  },
  {
    key: 'cfx_srps_vdps',
    label: 'SRP/VDP Ratio',
    filterColumnId: EProviderInitials.Carfax,
  },
  {
    key: 'ccm_price',
    label: 'Price',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  {
    key: 'ccm_deal_rating',
    label: 'Deal Rating',
    filterColumnId: EProviderInitials.CarsDotCom,
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  {
    key: 'ccm_total_srps',
    label: 'SRP',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  {
    key: 'ccm_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  {
    key: 'ccm_srps_vdps',
    label: 'SRP/VDP Ratio',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  {
    key: 'ga_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.GoogleAnalytics,
  }
];

export const pdfColumnsNew: TAggregatorColumnPDF<ChannelStatsReportResultsV2>[] = [
  {
    key: 'stock_number',
    label: 'Stock #',
  },
  {
    key: 'vehicle',
    label: 'Vehicle',
  },
  // {
  //   key: 'description',
  //   label: 'Description',
  // },
  {
    key: 'dol',
    label: 'DOL',
  },
  {
    key: 'photos_count',
    label: 'Photos',
  },
  {
    key: 'atc_price',
    label: 'Price',
    filterColumnId: EProviderInitials.Autotrader,
  },
  // {
  //   key : 'atc_deal_rating',
  //   label : 'Deal Rating',
  //   filterColumnId: EProviderInitials.Autotrader,
  // },
  {
    key: 'atc_total_srps',
    label: 'SRP',
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    key: 'atc_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    key: 'atc_srps_vdps',
    label: 'SRP/VDP Ratio',
    filterColumnId: EProviderInitials.Autotrader,
  },
  {
    key: 'cgr_price',
    label: 'Price',
    filterColumnId: EProviderInitials.CarGurus,
  },
  // {
  //   key: 'cgr_deal_rating',
  //   label: 'Deal Rating',
  //   filterColumnId: EProviderInitials.CarGurus,
  // },
  // {
  //   key: 'cgr_total_srps',
  //   label: 'SRP',
  //   filterColumnId: EProviderInitials.CarGurus,
  // },
  {
    key: 'cgr_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.CarGurus,
  },
  // {
  //   key: 'cgr_srps_vdps',
  //   label: 'SRP/VDP Ratio',
  //   filterColumnId: EProviderInitials.CarGurus,
  // },
  {
    key: 'cfx_price',
    label: 'Price',
    filterColumnId: EProviderInitials.Carfax,
  },
  // {
  //   key: 'cfx_deal_rating',
  //   label: 'Deal Rating',
  //   filterColumnId: EProviderInitials.Carfax,
  // },
  {
    key: 'cfx_total_srps',
    label: 'SRP',
    filterColumnId: EProviderInitials.Carfax,
  },
  {
    key: 'cfx_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.Carfax,
  },
  {
    key: 'cfx_srps_vdps',
    label: 'SRP/VDP Ratio',
    filterColumnId: EProviderInitials.Carfax,
  },
  {
    key: 'ccm_price',
    label: 'Price',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  // {
  //   key: 'ccm_deal_rating',
  //   label: 'Deal Rating',
  //   filterColumnId: EProviderInitials.CarsDotCom,
  // },
  {
    key: 'ccm_total_srps',
    label: 'SRP',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  {
    key: 'ccm_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  {
    key: 'ccm_srps_vdps',
    label: 'SRP/VDP Ratio',
    filterColumnId: EProviderInitials.CarsDotCom,
  },
  {
    key: 'ga_total_vdps',
    label: 'VDP',
    filterColumnId: EProviderInitials.GoogleAnalytics,
  }
];

export const xlsColumns: TAggregatorColumnXLS<ChannelStatsReportResultsV2>[] = [
  {
    label: "Vehicle",
    key: "description",
    colspan: 5,
    columns: [
      { label: "Stock #", key: "stock_number" },
      { label: "Vehicle", key: "vehicle" },
      { label: "Description", key: "description" },
      { label: "DOL", key: "dol" },
      { label: "Photos", key: "photos_count" },
    ],
  },
  {
    label: "Autotrader",
    key: "description",
    filterColumnId: EProviderInitials.Autotrader,
    colspan: 5,
    columns: [
      {
        label: "Price",
        key: "atc_price" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "Deal Rating",
        key: "atc_deal_rating" as keyof ChannelStatsReportResultsV2,
        func(value, row) {
            return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        },
      },
      {
        label: "SRP",
        key: "atc_total_srps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "VDP",
        key: "atc_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "SRP/VDP Ratio",
        key: "atc_srps_vdps" as keyof ChannelStatsReportResultsV2,
      }
    ],
  },
  {
    label: "CarGurus",
    key: "description",
    filterColumnId: EProviderInitials.CarGurus,
    colspan: 3,
    columns: [
      {
        label: "Price",
        key: "cgr_price" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "Deal Rating",
        key: "cgr_deal_rating" as keyof ChannelStatsReportResultsV2,
        func(value, row) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        },
      },
      // {
      //   label: "SRP",
      //   key: "cgr_total_srps" as keyof ChannelStatsReportResultsV2,
      // },
      {
        label: "VDP",
        key: "cgr_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "SRP/VDP Ratio",
      //   key: "cgr_srps_vdps" as keyof ChannelStatsReportResultsV2,
      // }
    ],
  },
  {
    label: "Carfax",
    key: "description",
    filterColumnId: EProviderInitials.Carfax,
    colspan: 5,
    columns: [
      {
        label: "Price",
        key: "cfx_price" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "Deal Rating",
        key: "cfx_deal_rating" as keyof ChannelStatsReportResultsV2,
        func(value, row) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        },
      },
      {
        label: "SRP",
        key: "cfx_total_srps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "VDP",
        key: "cfx_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "SRP/VDP Ratio",
        key: "cfx_srps_vdps" as keyof ChannelStatsReportResultsV2,
      }
    ],
  },
  {
    label: "Cars.com",
    key: "description",
    filterColumnId: EProviderInitials.CarsDotCom,
    colspan: 5,
    columns: [
      {
        label: "Price",
        key: "ccm_price" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "Deal Rating",
        key: "ccm_deal_rating" as keyof ChannelStatsReportResultsV2,
        func(value, row) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        },
      },
      {
        label: "SRP",
        key: "ccm_total_srps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "VDP",
        key: "ccm_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "SRP/VDP Ratio",
        key: "ccm_srps_vdps" as keyof ChannelStatsReportResultsV2,
      }
    ],
  },
  {
    label: "Website",
    key: "description",
    filterColumnId: EProviderInitials.GoogleAnalytics,
    colspan: 1,
    columns: [
      // {
      //   label: "Price",
      //   key: "ga_price" as keyof ChannelStatsReportResultsV2,
      // },
      // {
      //   label: "Deal Rating",
      //   key: "ga_deal_rating" as keyof ChannelStatsReportResultsV2,
      // },
      // {
      //   label: "SRP",
      //   key: "ga_total_srps" as keyof ChannelStatsReportResultsV2,
      // },
      {
        label: "VDP",
        key: "ga_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "SRP/VDP Ratio",
      //   key: "ga_srps_vdps" as keyof ChannelStatsReportResultsV2,
      // }
    ],
  },
];

export const columns = [
  columnHelper.group({
    header: "Vehicle Info",
    id: "vehicleInfo",
    columns: [
      columnHelper.accessor("stock_number", {
        cell: (info) => info.getValue(),
        enableSorting: false,
        header: () => <span className={styles.stockHeader}>Stock #</span>,
      }),
      columnHelper.accessor("vehicle", {
        id: "vehicle",
        cell: (info) => info.getValue(),
        header: () => <span className={styles.vehicleHeader}>Vehicle</span>,
      }),
      columnHelper.accessor("description", {
        cell: (info) =>
          info.getValue() ? (
            <SeeMoreText
              className={styles.descriptionCell}
              maxLength={52}
              text={info.getValue()}
            />
          ) : (
            "-"
          ),
        enableSorting: false,
        header: () => (
          <span className={styles.descriptionHeader}>Description</span>
        ),
      }),
      columnHelper.accessor("dol", {
        id: "dol",
        cell: (info) => info.getValue(),
        header: () => <span>DOL</span>,
      }),
      columnHelper.accessor("photos_count", {
        id: "photos_count",
        cell: (info) => info.getValue(),
        header: () => <span>Photos</span>,
      }),
    ],
  }),
  {
    header: "Autotrader",
    filterColumnId: EProviderInitials.Autotrader,
    classNameHeader: styles["autotraderHeader"],
    // columns: getProviderColumns("autotrader", "autotrader"),
    columns: [
      columnHelper.accessor('atc_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `atc_price`,
      }),
      columnHelper.accessor('atc_deal_rating', {
        cell: (info) => {
          const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
          return <Badge color={color} icon={icon} label={label} size="sm" />;
        },
        header: () => (
          <span className={styles.dealRatingHeader}>Deal Rating</span>
        ),
        id: `atc_deal_rating`,
      }),
      columnHelper.accessor('atc_total_srps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpHeader}>SRP</span>
        ),
        id: `atc_total_srps`,
      }),
      columnHelper.accessor('atc_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `atc_total_vdps`,
      }),
      columnHelper.accessor('atc_srps_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
        ),
        id: `atc_srps_vdps`,
      }),
    ]
  },
  {
    header: "CarGurus",
    filterColumnId: EProviderInitials.CarGurus,
    classNameHeader: styles["cargurusHeader"],
    // columns: getProviderColumns("cargurus", "cargurus"),
    columns: [
      columnHelper.accessor('cgr_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `cgr_price`,
      }),
      columnHelper.accessor('cgr_deal_rating', {
        cell: (info) => {
          const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
          return <Badge color={color} icon={icon} label={label} size="sm" />;
        },
        header: () => (
          <span className={styles.dealRatingHeader}>Deal Rating</span>
        ),
        id: `cgr_deal_rating`,
      }),
      // columnHelper.accessor('cgr_total_srps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpHeader}>SRP</span>
      //   ),
      //   id: `cgr_total_srps`,
      // }),
      columnHelper.accessor('cgr_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `cgr_total_vdps`,
      }),
      // columnHelper.accessor('cgr_srps_vdps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
      //   ),
      //   id: `cgr_srps_vdps`,
      // }),
    ]
  },
  {
    header: "Cars.com",
    filterColumnId: EProviderInitials.CarsDotCom,
    classNameHeader: styles["carsDotComHeader"],
    // columns: getProviderColumns("carsDotCom", "cars_com"),
    columns: [
      columnHelper.accessor('ccm_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `ccm_price`,
      }),
      columnHelper.accessor('ccm_deal_rating', {
        cell: (info) => {
          const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
          return <Badge color={color} icon={icon} label={label} size="sm" />;
        },
        header: () => (
          <span className={styles.dealRatingHeader}>Deal Rating</span>
        ),
        id: `ccm_deal_rating`,
      }),
      columnHelper.accessor('ccm_total_srps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpHeader}>SRP</span>
        ),
        id: `ccm_total_srps`,
      }),
      columnHelper.accessor('ccm_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `ccm_total_vdps`,
      }),
      columnHelper.accessor('ccm_srps_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
        ),
        id: `ccm_srps_vdps`,
      }),
    ]
  },
  // Temporarily hides carfax column for demo purposes
  {
    header: "Carfax",
    id: "carfax",
    filterColumnId: EProviderInitials.Carfax,
    classNameHeader: styles["carfaxComHeader"],
    // columns: getProviderColumns("carfax"),
    columns: [
      columnHelper.accessor('cfx_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `cfx_price`,
      }),
      columnHelper.accessor('cfx_deal_rating', {
        cell: (info) => {
          const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
          return <Badge color={color} icon={icon} label={label} size="sm" />;
        },
        header: () => (
          <span className={styles.dealRatingHeader}>Deal Rating</span>
        ),
        id: `cfx_deal_rating`,
      }),
      columnHelper.accessor('cfx_total_srps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpHeader}>SRP</span>
        ),
        id: `cfx_total_srps`,
      }),
      columnHelper.accessor('cfx_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `cfx_total_vdps`,
      }),
      columnHelper.accessor('cfx_srps_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
        ),
        id: `cfx_srps_vdps`,
      }),
    ]
  },
  {
    header: "Website",
    filterColumnId: EProviderInitials.GoogleAnalytics,
    classNameHeader: styles["websiteHeader"],
    // columns: getProviderColumns("website", "website"),
    columns: [
      // columnHelper.accessor('ga_price', {
      //   cell: (info) => info.getValue(),
      //   header: () => <span className={styles.priceHeader}>Price</span>,
      //   id: `ga_price`,
      // }),
      // columnHelper.accessor('ga_deal_rating', {
      //   cell: (info) => {
      //     const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
      //     return <Badge color={color} icon={icon} label={label} size="sm" />;
      //   },
      //   header: () => (
      //     <span className={styles.dealRatingHeader}>Deal Rating</span>
      //   ),
      //   id: `ga_deal_rating`,
      // }),
      // columnHelper.accessor('ga_total_srps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpHeader}>SRP</span>
      //   ),
      //   id: `ga_total_srps`,
      // }),
      columnHelper.accessor('ga_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `ga_total_vdps`,
      }), 
      // columnHelper.accessor('ga_srps_vdps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
      //   ),
      //   id: `ga_srps_vdps`,
      // }),
    ]
  },
];

// new inventory columns
export const xlsColumnsNew: TAggregatorColumnXLS<ChannelStatsReportResultsV2>[] = [
  {
    label: "Vehicle",
    key: "description",
    colspan: 5,
    columns: [
      { label: "Stock #", key: "stock_number" },
      { label: "Vehicle", key: "vehicle" },
      { label: "Description", key: "description" },
      { label: "DOL", key: "dol" },
      { label: "Photos", key: "photos_count" },
    ],
  },
  {
    label: "Autotrader",
    key: "description",
    filterColumnId: EProviderInitials.Autotrader,
    colspan: 4,
    columns: [
      {
        label: "Price",
        key: "atc_price" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "Deal Rating",
      //   key: "atc_deal_rating" as keyof ChannelStatsReportResultsV2,
      // },
      {
        label: "SRP",
        key: "atc_total_srps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "VDP",
        key: "atc_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "SRP/VDP Ratio",
        key: "atc_srps_vdps" as keyof ChannelStatsReportResultsV2,
      }
    ],
  },
  {
    label: "CarGurus",
    key: "description",
    filterColumnId: EProviderInitials.CarGurus,
    colspan: 2,
    columns: [
      {
        label: "Price",
        key: "cgr_price" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "Deal Rating",
      //   key: "cgr_deal_rating" as keyof ChannelStatsReportResultsV2,
      // },
      // {
      //   label: "SRP",
      //   key: "cgr_total_srps" as keyof ChannelStatsReportResultsV2,
      // },
      {
        label: "VDP",
        key: "cgr_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "SRP/VDP Ratio",
      //   key: "cgr_srps_vdps" as keyof ChannelStatsReportResultsV2,
      // }
    ],
  },
  {
    label: "Carfax",
    key: "description",
    filterColumnId: EProviderInitials.Carfax,
    colspan: 4,
    columns: [
      {
        label: "Price",
        key: "cfx_price" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "Deal Rating",
      //   key: "cfx_deal_rating" as keyof ChannelStatsReportResultsV2,
      // },
      {
        label: "SRP",
        key: "cfx_total_srps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "VDP",
        key: "cfx_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "SRP/VDP Ratio",
        key: "cfx_srps_vdps" as keyof ChannelStatsReportResultsV2,
      }
    ],
  },
  {
    label: "Cars.com",
    key: "description",
    filterColumnId: EProviderInitials.CarsDotCom,
    colspan: 4,
    columns: [
      {
        label: "Price",
        key: "ccm_price" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "Deal Rating",
      //   key: "ccm_deal_rating" as keyof ChannelStatsReportResultsV2,
      // },
      {
        label: "SRP",
        key: "ccm_total_srps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "VDP",
        key: "ccm_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      {
        label: "SRP/VDP Ratio",
        key: "ccm_srps_vdps" as keyof ChannelStatsReportResultsV2,
      }
    ],
  },
  {
    label: "Website",
    key: "description",
    filterColumnId: EProviderInitials.GoogleAnalytics,
    colspan: 1,
    columns: [
      // {
      //   label: "Price",
      //   key: "ga_price" as keyof ChannelStatsReportResultsV2,
      // },
      // {
      //   label: "Deal Rating",
      //   key: "ga_deal_rating" as keyof ChannelStatsReportResultsV2,
      // },
      // {
      //   label: "SRP",
      //   key: "ga_total_srps" as keyof ChannelStatsReportResultsV2,
      // },
      {
        label: "VDP",
        key: "ga_total_vdps" as keyof ChannelStatsReportResultsV2,
      },
      // {
      //   label: "SRP/VDP Ratio",
      //   key: "ga_srps_vdps" as keyof ChannelStatsReportResultsV2,
      // }
    ],
  },
];

export const columnsNew = [
  columnHelper.group({
    header: "Vehicle Info",
    id: "vehicleInfo",
    columns: [
      columnHelper.accessor("stock_number", {
        cell: (info) => info.getValue(),
        enableSorting: false,
        header: () => <span className={styles.stockHeader}>Stock #</span>,
      }),
      columnHelper.accessor("vehicle", {
        id: "vehicle",
        cell: (info) => info.getValue(),
        header: () => <span className={styles.vehicleHeader}>Vehicle</span>,
      }),
      columnHelper.accessor("description", {
        cell: (info) =>
          info.getValue() ? (
            <SeeMoreText
              className={styles.descriptionCell}
              maxLength={52}
              text={info.getValue()}
            />
          ) : (
            "-"
          ),
        enableSorting: false,
        header: () => (
          <span className={styles.descriptionHeader}>Description</span>
        ),
      }),
      columnHelper.accessor("dol", {
        id: "dol",
        cell: (info) => info.getValue(),
        header: () => <span>DOL</span>,
      }),
      columnHelper.accessor("photos_count", {
        id: "photos_count",
        cell: (info) => info.getValue(),
        header: () => <span>Photos</span>,
      }),
    ],
  }),
  {
    header: "Autotrader",
    filterColumnId: EProviderInitials.Autotrader,
    classNameHeader: styles["autotraderHeader"],
    // columns: getProviderColumns("autotrader", "autotrader"),
    columns: [
      columnHelper.accessor('atc_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `atc_price`,
      }),
      // columnHelper.accessor('atc_deal_rating', {
      //   cell: (info) => {
      //     const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
      //     return <Badge color={color} icon={icon} label={label} size="sm" />;
      //   },
      //   header: () => (
      //     <span className={styles.dealRatingHeader}>Deal Rating</span>
      //   ),
      //   id: `atc_deal_rating`,
      // }),
      columnHelper.accessor('atc_total_srps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpHeader}>SRP</span>
        ),
        id: `atc_total_srps`,
      }),
      columnHelper.accessor('atc_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `atc_total_vdps`,
      }),
      columnHelper.accessor('atc_srps_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
        ),
        id: `atc_srps_vdps`,
      }),
    ]
  },
  {
    header: "CarGurus",
    filterColumnId: EProviderInitials.CarGurus,
    classNameHeader: styles["cargurusHeader"],
    // columns: getProviderColumns("cargurus", "cargurus"),
    columns: [
      columnHelper.accessor('cgr_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `cgr_price`,
      }),
      // columnHelper.accessor('cgr_deal_rating', {
      //   cell: (info) => {
      //     const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
      //     return <Badge color={color} icon={icon} label={label} size="sm" />;
      //   },
      //   header: () => (
      //     <span className={styles.dealRatingHeader}>Deal Rating</span>
      //   ),
      //   id: `cgr_deal_rating`,
      // }),
      // columnHelper.accessor('cgr_total_srps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpHeader}>SRP</span>
      //   ),
      //   id: `cgr_total_srps`,
      // }),
      columnHelper.accessor('cgr_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `cgr_total_vdps`,
      }),
      // columnHelper.accessor('cgr_srps_vdps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
      //   ),
      //   id: `cgr_srps_vdps`,
      // }),
    ]
  },
  {
    header: "Cars.com",
    filterColumnId: EProviderInitials.CarsDotCom,
    classNameHeader: styles["carsDotComHeader"],
    // columns: getProviderColumns("carsDotCom", "cars_com"),
    columns: [
      columnHelper.accessor('ccm_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `ccm_price`,
      }),
      // columnHelper.accessor('ccm_deal_rating', {
      //   cell: (info) => {
      //     const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
      //     return <Badge color={color} icon={icon} label={label} size="sm" />;
      //   },
      //   header: () => (
      //     <span className={styles.dealRatingHeader}>Deal Rating</span>
      //   ),
      //   id: `ccm_deal_rating`,
      // }),
      columnHelper.accessor('ccm_total_srps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpHeader}>SRP</span>
        ),
        id: `ccm_total_srps`,
      }),
      columnHelper.accessor('ccm_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `ccm_total_vdps`,
      }),
      columnHelper.accessor('ccm_srps_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
        ),
        id: `ccm_srps_vdps`,
      }),
    ]
  },
  // Temporarily hides carfax column for demo purposes
  {
    header: "Carfax",
    id: "carfax",
    filterColumnId: EProviderInitials.Carfax,
    classNameHeader: styles["carfaxComHeader"],
    // columns: getProviderColumns("carfax"),
    columns: [
      columnHelper.accessor('cfx_price', {
        cell: (info) => info.getValue(),
        header: () => <span className={styles.priceHeader}>Price</span>,
        id: `cfx_price`,
      }),
      // columnHelper.accessor('cfx_deal_rating', {
      //   cell: (info) => {
      //     const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
      //     return <Badge color={color} icon={icon} label={label} size="sm" />;
      //   },
      //   header: () => (
      //     <span className={styles.dealRatingHeader}>Deal Rating</span>
      //   ),
      //   id: `cfx_deal_rating`,
      // }),
      columnHelper.accessor('cfx_total_srps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpHeader}>SRP</span>
        ),
        id: `cfx_total_srps`,
      }),
      columnHelper.accessor('cfx_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `cfx_total_vdps`,
      }),
      columnHelper.accessor('cfx_srps_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
        ),
        id: `cfx_srps_vdps`,
      }),
    ]
  },
  {
    header: "Website",
    filterColumnId: EProviderInitials.GoogleAnalytics,
    classNameHeader: styles["websiteHeader"],
    // columns: getProviderColumns("website", "website"),
    columns: [
      // columnHelper.accessor('ga_price', {
      //   cell: (info) => info.getValue(),
      //   header: () => <span className={styles.priceHeader}>Price</span>,
      //   id: `ga_price`,
      // }),
      // columnHelper.accessor('ga_deal_rating', {
      //   cell: (info) => {
      //     const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
      //     return <Badge color={color} icon={icon} label={label} size="sm" />;
      //   },
      //   header: () => (
      //     <span className={styles.dealRatingHeader}>Deal Rating</span>
      //   ),
      //   id: `ga_deal_rating`,
      // }),
      // columnHelper.accessor('ga_total_srps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpHeader}>SRP</span>
      //   ),
      //   id: `ga_total_srps`,
      // }),
      columnHelper.accessor('ga_total_vdps', {
        cell: (info) => info.getValue(),
        header: () => (
          <span className={styles.vdpHeader}>VDP</span>
        ),
        id: `ga_total_vdps`,
      }), 
      // columnHelper.accessor('ga_srps_vdps', {
      //   cell: (info) => info.getValue(),
      //   header: () => (
      //     <span className={styles.srpVdpRatioHeader}>SRP/VDP Ratio</span>
      //   ),
      //   id: `ga_srps_vdps`,
      // }),
    ]
  },
];
