import { createColumnHelper } from "@tanstack/react-table";
import { IAPIDealRatingsV2 } from "api/useGetSales";
import Badge from "components/Badge";
import {
  EAPIProviderRanks,
  EAPIProviderRanksReverse,
  EProviderInitials,
} from "types/APIProviderRanks";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import getProviderRank from "utils/getProviderRank";

const columnHelper = createColumnHelper<IAPIDealRatingsV2>();

export const columns = [
  columnHelper.accessor("stock_number", {
    id: "stock_number",
    cell: (info) => info.getValue(),
    header: () => <span>Stock #</span>,
  }),
  columnHelper.accessor('vehicle', {
    id: "vehicle",
    cell: (info) => info.getValue(),
    header: () => <span>Vehicle</span>,
    size: 379,
  }),
  {
    filterColumnId: EProviderInitials.CarGurus,
    ...columnHelper.accessor(
      'cgr_deal_rating',
      {
        id: "cgr_deal_rating",
        cell: (info) => {
          const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
          return <Badge color={color} icon={icon} label={label} size="sm" />;
        },
        header: () => <span>CarGurus</span>,
      }
    ),
  },
  {
    filterColumnId: EProviderInitials.Autotrader,
    ...columnHelper.accessor(
      'atc_deal_rating',
      {
        id: "atc_deal_rating",
        cell: (info) => {
          const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
          return <Badge color={color} icon={icon} label={label} size="sm" />;
        },
        header: () => <span>Autotrader</span>,
      }
    ),
  },
  {
    filterColumnId: EProviderInitials.CarsDotCom,
    ...columnHelper.accessor(
      'ccm_deal_rating',
      {
        id: "ccm_deal_rating",
        cell: (info) => {
          const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
          return <Badge color={color} icon={icon} label={label} size="sm" />;
        },
        header: () => <span>Cars.com</span>,
      }
    ),
  },
  // Temporarily hides carfax column for demo purposes
  // columnHelper.accessor(
  //   (row) => getDealRatingPair(row.deal_ratings, "carfax"),
  //   {
  //     id: "carfax_deal_rating",
  //     cell: getProviderRatingCell,
  //     enableSorting: false,
  //     header: () => <span>Carfax</span>,
  //   }
  // ),
  columnHelper.accessor("price", {
    id: "price",
    cell: (info) => info.getValue(),
    header: () => <span>Price</span>,
  }),
  // columnHelper.accessor("default_percentage_to_market", {
  //   id: "default_percentage_to_market",
  //   cell: (info) =>
  //     info.getValue() === null
  //       ? "N/A"
  //       : `${((info.getValue() || 0) * 100).toLocaleString()}%`,
  //   header: () => <span>Default % of Market</span>,
  //   size: 102,
  // }),
  columnHelper.accessor("dol", {
    id: "dol",
    cell: (info) => info.getValue(),
    header: () => <span>DOL</span>,
    size: 81,
  }),
  columnHelper.accessor("photos_count", {
    id: "photos_count",
    cell: (info) => info.getValue(),
    header: () => <span># of Photos</span>,
    size: 96,
  }),
];

export const pdfColumns: TAggregatorColumnPDF<IAPIDealRatingsV2>[] = [
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "vehicle",
  },
  {
    label: "CarGurus",
    key: "cgr_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  {
    label: "Autotrader",
    key: "atc_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  {
    label: "Cars.com",
    key: "ccm_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  // Temporarily hides carfax column for demo purposes
  // {
  //   label: "Carfax",
  //   key: "deal_ratings",
  //   func: (value) =>
  //     getProviderRatingCellPDF(value as IPairProviderRatings[], "carfax"),
  // },
  {
    label: "Price",
    key: "price",
  },
  // {
  //   label: "Default % of Market",
  //   key: "default_percentage_to_market",
  //   func: (value) =>
  //     value === null
  //       ? "N/A"
  //       : `${(((value as number) || 0) * 100).toLocaleString()}%`,
  // },
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "# of Photos",
    key: "photos_count",
  },
];

export const xlsColumns: TAggregatorColumnXLS<IAPIDealRatingsV2>[] = [
  {
    label: "Stock #",
    key: "stock_number",
  },
  {
    label: "Vehicle",
    key: "vehicle",
  },
  {
    label: "CarGurus",
    key: "cgr_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  {
    label: "Autotrader",
    key: "atc_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  {
    label: "Cars.com",
    key: "ccm_deal_rating",
    func(value) {
      return EAPIProviderRanksReverse[value as EAPIProviderRanks];
    }
  },
  // Temporarily hides carfax column for demo purposes
  // {
  //   label: "Carfax",
  //   key: "deal_ratings",
  //   func: (value) =>
  //     getProviderRatingCellPDF(value as IPairProviderRatings[], "carfax"),
  // },
  {
    label: "Price",
    key: "price",
  },
  // {
  //   label: "Default % of Market",
  //   key: "default_percentage_to_market",
  //   func: (value) =>
  //     value === null
  //       ? "N/A"
  //       : `${(((value as number) || 0) * 100).toLocaleString()}%`,
  // },
  {
    label: "DOL",
    key: "dol",
  },
  {
    label: "# of Photos",
    key: "photos_count",
  },
];

export const defaultOffset = 0;
