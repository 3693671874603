import { FC, ReactNode } from "react";
import cs from "classnames";
import styles from "./styles.module.scss";

export type EColors =
  | "blueLight"
  | "primary"
  | "error"
  | "gray"
  | "indigo"
  | "success"
  | "warning";

interface IProps {
  className?: string;
  color?: EColors;
  icon?: ReactNode;
  label: ReactNode;
  size?: "sm" | "md" | "lg";
}

const Badge: FC<IProps> = ({
  className = "",
  color = "primary",
  icon,
  label,
  size = "md",
}) => (
  <div
    className={cs(styles.badge, styles[color], styles[size], className, {
      [styles.hasIcon]: !!icon,
    })}
  >
    {icon}
    {label}
  </div>
);

export default Badge;
