import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Button from "components/Button";
import TextInput from "components/TextInput";
import useAuthApi from "hooks/useAuthApi";
import ERoutes from "types/Routes";
import styles from "./styles.module.scss";

const registerSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords don't match")
    .required("Confirm Password is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/,
      "Requires alpha-numeric(a-Z & 0-9) and special characters(@$!%*#?&)"
    )
    .required("Password is required"),
});

interface IFormRegister {
  confirmPassword: string;
  firstName: string;
  lastName: string;
  password: string;
}

const SignUp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { registerUser } = useAuthApi();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormRegister>({ resolver: yupResolver(registerSchema) });

  const onSubmit = async (formData: IFormRegister) => {
    try {
      const results = await registerUser({
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        token: searchParams.get("token") || "",
      });

      await results.json();

      navigate(ERoutes.Login, { replace: true });
      toast.success("Registered Successfully!");
    } catch {
      toast.error("Something went wrong. Please contact your admin");
    }
  };

  return (
    <>
      <div className={styles.background} />
      <div className={styles.createUser}>
        <div className={styles["createUser__banner"]}>
          <img src="assets/images/logo-bright.svg" alt="logo" width="137px" />
          <h1>Almost there!</h1>
          <p>Finish creating your account to access VinDrives</p>
        </div>
        <form
          className={styles["createUser__form"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            disabled={isSubmitting}
            errorMessage={errors.firstName?.message as string}
            label="First Name"
            name="firstName"
            placeholder="First Name"
            register={register}
            type="text"
          />

          <TextInput
            disabled={isSubmitting}
            errorMessage={errors.lastName?.message as string}
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            register={register}
            type="text"
          />

          <TextInput
            disabled={isSubmitting}
            errorMessage={errors.password?.message as string}
            label="Password"
            name="password"
            register={register}
            type="password"
          />

          <TextInput
            disabled={isSubmitting}
            errorMessage={errors.confirmPassword?.message as string}
            label="Confirm Password"
            name="confirmPassword"
            register={register}
            type="password"
          />

          <Button size="lg" disabled={isSubmitting}>
            Add
          </Button>
        </form>
      </div>
    </>
  );
};

export default SignUp;
