export enum EQueryParameterKeys {
  ActiveTable = "activeTable",
  DaysOnLot = "DaysOnLot",
  EndDate = "endDate",
  ResultScore = "resultScore",
  StartDate = "startDate",
  TipReportTableSource = "tipReportTableSource",
  DOL = "dol",
  DolRange = "dolRange",
  LeadsZero = "leadsZero",
  Source = "source",
  dolFrom = "dol_from",
  dolTo = "dol_to",
  Search = "search"
}

export enum ERoutesAnchors {
  MerchandisingTables = "merchandising-tables",
  ReportingTables = "reporting-tables",
}

enum ERoutes {
  Home = "/",
  ForgotPassword = "/forgot-password",
  Login = "/login",
  MarketingResults = "/marketing-results",
  Merchandising = "/merchandising",
  Reporting = "/reporting",
  SignUp = "/signup",
  VDPResults = "/vdp-results",
  VDPResultsV2 = "/vdp-results-v2",
}

export default ERoutes;
