export enum EAPIProviderRanks {
  Overpriced = "OP",
  High = "HG",
  Fair = "FR",
  Good = "GD",
  Great = "GT",
  NotApplicable = "N/A",
  Unknown = "UK",
}

// swap key and value of EAPIProviderRanks
export enum EAPIProviderRanksReverse {
  HG = "High",
  FR = "Fair",
  GD = "Good",
  GT = "Great",
  "N/A" = "NotApplicable",
  UK = "Unknown",
  OP = "Overpriced",
}


export enum EProviderInitials {
  Autotrader = "ATC",
  CarsDotCom = "CCM",
  Carfax = "CFX",
  CarGurus = "CGR",
  GoogleAnalytics = "GA",
  Vinmanager = "VNM",
}

export type TProviders =
  | "autotrader"
  | "cargurus"
  | "cars_com"
  | "carfax"
  | "website";

export interface IPairProviderRatings {
  source: TProviders;
  rating: EAPIProviderRanks;
}
