import { useMemo } from "react";
import { format } from "date-fns";
import useGetSales, { EModules } from "api/useGetSales";
import StatisticCard, { IStatisticCardProps } from "components/StatisticCard";
import apiDateFormat from "constants/apiDateFormat";
import { currentMonth, today } from "constants/commonDateRanges";
import styles from "pages/Home/styles.module.scss";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

const ResultsSummary = () => {

  const userAuth = useAppSelector(selectUserData);

  // const module = userAuth.inventory.value === 'new' ? EModules.ResultsSummaryV2 : EModules.ResultsSummary;

  const resultSummaryVDPS = useGetSales<EModules.ResultsSummaryVDPS>({
    module: EModules.ResultsSummaryVDPS,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      date_to: format(today.startDate, apiDateFormat),
      last_n_days: 30,
      inventory: userAuth.inventory.value,
    },
  });

  const resultSummary = useGetSales<EModules.ResultsSummaryV2>({
    module: EModules.ResultsSummaryV2,
    params: {
      dealership_uid: userAuth.dealerV2.uid,
      date_from: format(currentMonth.startDate, apiDateFormat),
      date_to: format(currentMonth.endDate, apiDateFormat),
      inventory: userAuth.inventory.value,
    },
  });

  const formattedResultsSummary: IStatisticCardProps[] = useMemo(() => {
    
    const {
      sales_front_line_ready_ratio,
      front_line_ready,
      sales_pace,
      average_days_to_sell,
      // front_line_ready_to_sales_goal,
      // sales_goal
    } = resultSummary.data || {};

    const {
      average_vdps_per_vehicle,
      total_vdps
    } = resultSummaryVDPS.data || {};

    const getArrowDirection = (growth: number | null) =>
      typeof growth === "number" ? (growth > 0 ? "up" : "down") : null;

    return [
      {
        leftSection: {
          // arrowDirection: getArrowDirection(0),
          // growth: 0,
          showGrowth: false,
          label: "Total VDPs",
          total: total_vdps || 0,
          description: "Last 30 Days"
        },
        rightSection: {
          arrowDirection: getArrowDirection(0),
          growth: 0,
          showGrowth: false,
          label: "Avg VDPs / Vehicle",
          total: average_vdps_per_vehicle || 0,
          description: "Last 30 Days"
        },
      },
      {
        rightSection: {
          arrowDirection: getArrowDirection(
            typeof sales_front_line_ready_ratio?.growth !== "undefined"
              ? sales_front_line_ready_ratio.growth
              : 0
          ),
          growth: sales_front_line_ready_ratio?.growth || 0,
          label: "Sales to FLR Ratio",
          total: sales_front_line_ready_ratio?.total || 0,
          showGrowth: true,
          description: "Month to Date"
        },
        leftSection: {
          arrowDirection: getArrowDirection(
            typeof front_line_ready?.growth !== "undefined"
              ? front_line_ready.growth
              : 0
          ),
          growth: front_line_ready?.growth || 0,
          label: "Front Line Ready",
          total: front_line_ready?.total || 0,
          showGrowth: true,
          description: "Month to Date"
        },
      },
      {
        leftSection: {
          arrowDirection: getArrowDirection(
            typeof sales_pace?.growth !== "undefined" ? sales_pace.growth : 0
          ),
          growth: sales_pace?.growth || 0,
          label: "Sales Pace",
          total: sales_pace?.total || 0,
          showGrowth: true,
          description: "Month to Date"
        },
        rightSection: {
          arrowDirection:
            typeof average_days_to_sell?.growth === "number"
              ? average_days_to_sell.growth > 0
                ? "down"
                : "up"
              : null,
          growth: average_days_to_sell?.growth || 0,
          label: "Average Days to Sell",
          total: average_days_to_sell?.total || 0,
          showGrowth: true,
          description: "Month to Date"
        },
      },
    ];
  }, [resultSummary.data, resultSummaryVDPS.data]);

  return (
    <div className={styles.metricsContainer}>
      {formattedResultsSummary.map(({ leftSection, rightSection }, index) => (
        <StatisticCard
          key={index}
          leftSection={leftSection}
          rightSection={rightSection}
        />
      ))}
    </div>
  );
};

export default ResultsSummary;
