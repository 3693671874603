import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import cs from "classnames";
import * as yup from "yup";
import Typography from "components/Typography";
import TextInput from "components/TextInput";
import Button from "components/Button";
// import CheckBox from "components/Checkbox";
import ERoutes from "types/Routes";
import styles from "./styles.module.scss";
import { loginUser } from "store/auth/authSlice";
import { useAppDispatch } from "hooks/store";

interface IFormLogin {
  email: string;
  login: string;
  recaptchaToken: string | null;
  password: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup.string().required("Password is required"),
  recaptchaToken: yup.string().required("Verification is required"),
});

const LoginPage = () => {
  
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<IFormLogin>({ resolver: yupResolver(loginSchema) });

  const [recaptchaKey, setRecaptchaKey] = useState(0);

  const handleRecaptchaError = () => {
    // Increment the key to force re-render
    setRecaptchaKey(prevKey => prevKey + 1);
  };

  const dispatch = useAppDispatch();
  
  // const { loginUser: loginUserContext } = useAuthApi();

  const navigate = useNavigate();
  
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = async (data: IFormLogin) => {

    try {
      const actionResult = await dispatch(loginUser({
        email: data.email,
        password: data.password,
        recaptchaToken: data.recaptchaToken
      }));

      const result = await actionResult.payload;

      console.log(result);
      if (result.statusCode === 200) {
        reset();
        navigate(ERoutes.Home, { replace: true });
      } else {
        setError("login", {
          message: result.message,
        });
      }

    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please contact your admin");
    }
  };

  const onTogglePassword = useCallback(
    () => setShowPassword((prevState) => !prevState),
    []
  );

  return (
    <>
      <div className={styles.background} />
      <div className={styles.login}>
        <div className={styles["login__banner"]}>
          <img src="assets/images/logo.png" alt="logo" width="137px" />
          <h1>Welcome to VinDrives!</h1>
          <p>To drive results, please enter your details.</p>
        </div>
        <form
          className={styles["login__form"]}
          onSubmit={handleSubmit(onSubmit)}
        >
          {errors?.login?.message && (
            <span className={styles["login__form__error"]}>
              {errors.login.message as string}
            </span>
          )}
          <TextInput
            disabled={isSubmitting}
            placeholder="user@email.com"
            label="Email"
            icon={
              <img
                src="assets/icons/mail.svg"
                alt="email-icon"
                width="20px"
                height="auto"
              />
            }
            type="text"
            name="email"
            register={register}
            errorMessage={
              (errors.email?.message as string) ??
              (errors?.login !== undefined ? "" : undefined)
            }
          />
          <TextInput
            disabled={isSubmitting}
            placeholder="Password"
            label="Password"
            icon={
              <img
                src="assets/icons/lock.svg"
                alt="password-icon"
                width="20px"
                height="auto"
              />
            }
            iconSuffix={
              <img
                className={cs(styles["login__form__togglePassword"], {
                  [styles["login__form__togglePassword--hide"]]: showPassword,
                })}
                src="assets/icons/eye-off.svg"
                alt="show-password"
                width="16px"
                height="auto"
                onClick={onTogglePassword}
                role="button"
              />
            }
            name="password"
            type={showPassword ? "text" : "password"}
            register={register}
            errorMessage={
              (errors.password?.message as string) ??
              (errors?.login !== undefined ? "" : undefined)
            }
          />
          <div className={styles["login__form__actions"]}>
            {/* <CheckBox
              disabled={isSubmitting}
              name="remember"
              label="Remember for 30 days"
              register={register}
            /> */}
            <Link to={ERoutes.ForgotPassword}>Forgot password</Link>
          </div>
          <div className={styles["login__form__captcha"]}>
            <Controller
              control={control}
              name="recaptchaToken"
              render={({ field: { onChange } }) => (
                <ReCAPTCHA
                  key={recaptchaKey}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                  onChange={(value) => onChange(value)}
                  onErrored={handleRecaptchaError}
                />
              )}
            />
            {errors.recaptchaToken && (
              <Typography
                className={styles["login__form__captcha__error"]}
                fontSize="text-sm"
              >
                {errors.recaptchaToken.message}
              </Typography>
            )}
          </div>
          <Button disabled={isSubmitting} variant="primary" size="xl">
            Sign in
          </Button>
        </form>
      </div>
    </>
  );
};
export default LoginPage;
