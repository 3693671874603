import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useGetSales, {
  EModules,
  VPDsByChannelDataV2,
  getSalesAsync,
} from "api/useGetSales";
import { IDateRangeProps } from "components/DateRange";
import Table from "components/Table";
import apiDateFormat from "constants/apiDateFormat";
import commonDateRanges, { ICommonDateRange, lastWeek } from "constants/commonDateRanges";
import {
  TActionProps,
  TGeneratePdfFunction,
  TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import {
  ELocalSavedFilters,
  ITableMarketing,
  getDefaultFilters,
  setDefaultFilters,
} from "utils/defaultFilters";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";
import useUrlQuery from "hooks/useUrlQuery";
import { CommonUrlParams } from "utils/urlParams";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { TInventoryOption, TInventoryOptionValue, inventories } from "constants/common";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { selectUserData, setInventory } from "store/auth/authSlice";

const filterVinmanager = ({ source }: VPDsByChannelDataV2) =>
  source.toLowerCase() !== "vinmanager";

const VDPsByChannel = () => {

  const userAuth = useAppSelector(selectUserData);

  const dispatch = useAppDispatch();

  const { getParam, setParam } = useUrlQuery<CommonUrlParams>();

  const startDateParams = getParam("startDate");
  const endDateParams = getParam("endDate");

  const defaultFilters = getDefaultFilters<ITableMarketing>(
    ELocalSavedFilters.VDPsByChannelTable,
    { dateRange: lastWeek }
  );

  const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
    {
      startDate: startDateParams ? new Date(startDateParams) : defaultFilters.dateRange.startDate,
      endDate: endDateParams ? new Date(endDateParams) : defaultFilters.dateRange.endDate,
    }
  );

  useEffect(() => {
    setParam({
      startDate: format(dateRange.startDate, apiDateFormat),
      endDate: format(dateRange.endDate, apiDateFormat),
      inventory: userAuth.inventory.value
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.endDate, dateRange.startDate, userAuth.inventory.value]);

  const fetchParameters = useMemo(
    () => ({
      module: EModules.VDPsByChannelV2,
      params: {
        date_from: format(dateRange.startDate, apiDateFormat),
        date_to: format(dateRange.endDate, apiDateFormat),
        dealership_uid: userAuth.dealerV2.uid,
        inventory: userAuth.inventory.value,
        ...(userAuth.inventory.value === 'all' && {
          inventory: '',
        }) as any,
      },
    }),
    [dateRange.endDate, dateRange.startDate, userAuth.dealerV2.uid, userAuth.inventory.value]
  );
  const { data, isFetching } =
    useGetSales<EModules.VDPsByChannelV2>(fetchParameters);

  const onChangeDateRange: IDateRangeProps["onChange"] = useCallback(
    ([startDate, endDate]) => {
      setDateRange({ startDate, endDate });

      // set default values
      setDefaultFilters<ITableMarketing>(ELocalSavedFilters.VDPsByChannelTable, {
        dateRange: {
          startDate: startDate,
          endDate: endDate,
        }
      });

    }, []);

  const activeIndexQuickOption = useMemo(
    () =>
      commonDateRanges.findIndex(
        ({ value: quickOptionValue }) =>
          format(quickOptionValue.startDate, apiDateFormat) ===
            format(dateRange.startDate, apiDateFormat) &&
          format(quickOptionValue.endDate, apiDateFormat) ===
            format(dateRange.endDate, apiDateFormat)
      ),
    [dateRange.endDate, dateRange.startDate]
  );

  const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
    async (onGeneratePDF, setIsLoading) => {
      setIsLoading(true);
      try {
        const results = await getSalesAsync<EModules.VDPsByChannelV2>(
          fetchParameters
        )();

        const tempPdfColumn = getPdfColumns(userAuth.inventory.value);

        const bodyFormatted = results.filter(filterVinmanager).map((values, index) => {
          return tempPdfColumn.map(
            ({ func, key }) => key === 'rank' ? ++index : func?.(values[key]) || values[key]
          );
        });

        const dateRangeTitle = activeIndexQuickOption !== -1 ? `${commonDateRanges[activeIndexQuickOption].label} (${formattedDateRange(dateRange)})` : formattedDateRange(dateRange);

        onGeneratePDF(
          {
            head: [tempPdfColumn.map(({ label }) => label)],
            body: bodyFormatted,
            ...onGeneratePDFDefaultType(`VDPs by Channel`, dateRangeTitle)
          },
          "vdps-by-channel.pdf",
          {
            compress: true
          }
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export PDF file. Please contact your admin");
      }
    },
    [activeIndexQuickOption, dateRange, fetchParameters, userAuth.inventory.value]
  );

  const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
    async (generateXLS, setIsLoading) => {
      setIsLoading(true);
      try {
        const results = await getSalesAsync<EModules.VDPsByChannelV2>(
          fetchParameters
        )();

        const tempXLSColumn = getXLSColumns(userAuth.inventory.value);

        const bodyFormatted = results.filter(filterVinmanager).map((values, index) => {
          return tempXLSColumn.map(
            ({ func, key }) => key === 'rank' ? ++index : func?.(values[key], values) || values[key]
          );
        });

        generateXLS(
          {
            head: [tempXLSColumn.map(({ label }) => label)],
            body: bodyFormatted,
          },
          "vdps-by-channel.xls"
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export XLS file. Please contact your admin");
      }
    },
    [fetchParameters, userAuth.inventory.value]
  );

  const filteredData = useMemo(() => data?.filter(filterVinmanager), [data]);

  const onRadioChange = (nextValue: TInventoryOptionValue) => {
    const selectedInventory: TInventoryOption = inventories.find((inventory) => inventory.value === nextValue) || inventories[0];
    dispatch(setInventory({ inventory: selectedInventory }));
    // setInventory(selectedInventory);
  };

  const filteredDateRanges: ICommonDateRange[] = commonDateRanges.filter(range => {
    return range.label !== "Last 30 Days" && range.label !== "Last 60 Days";
  });

  const filteredInventories = useMemo(() => {
    if (userAuth.dealerV2.new && userAuth.dealerV2.used) {
        return inventories;
    }
    return inventories.filter((inventory) => {
        if (userAuth.dealerV2.used && inventory.value === 'used') {
            return true;
        }
        if (userAuth.dealerV2.new && inventory.value === 'new') {
            return true;
        }
        return false;
    });
  }, [userAuth.dealerV2.new, userAuth.dealerV2.used]);

  const customButtons = (): React.ReactNode => (
    <>
       <RadioGroup onChange={onRadioChange} value={userAuth.inventory.value}>
        <Stack direction='row'>
          {filteredInventories.map((inventory) => (
            <Radio key={inventory.value} value={inventory.value} colorScheme='red'>
              {inventory.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </>
  );

  return (
    <Table<VPDsByChannelDataV2>
      dateRange={{ onChange: onChangeDateRange, value: dateRange, quickOptions: filteredDateRanges }}
      data={filteredData || []}
      columns={getColumns(userAuth.inventory.value)}
      isLoading={isFetching}
      onGeneratePDF={onGeneratePDF}
      onDownloadPDF={onGeneratePDF}
      onDownloadXLS={onGenerateXLSX}
      sort={{ mode: "client" }}
      customButtons={customButtons()}
      showPagination={false}
      actionVersion="v2"
      actionConfig={{
        isDownloadPDF: true,
        isDownloadXLS: true,
        isEmailPDF: true,
        isEmailXLS: true,
        isPrintPDF: true,
      }}
    />
  );
};
export default VDPsByChannel;
