import { createColumnHelper } from "@tanstack/react-table";
import { IAPIRecentActivityReportV2 } from "api/useGetReports";
import Badge from "components/Badge";
import { TInventoryOptionValue } from "constants/common";
import ResultScoreColorBadge from "pages/VDPResults/components/ResultsScoreBadge";
import { EAPIProviderRanks, EProviderInitials } from "types/APIProviderRanks";
import formatCellVehicle from "utils/formatCellVehicle";
import getProviderRank from "utils/getProviderRank";

export const columnHelper = createColumnHelper<IAPIRecentActivityReportV2>();

export const getColumns = (inventory: TInventoryOptionValue) => (
    [
      columnHelper.accessor((row) => row.result_score, {
        id: "result_score",
        cell: (info) => <ResultScoreColorBadge value={info.getValue()} />,
        header: 'Results Score',
        size: 180,
        meta: {
          showTooltip: false
        }
      }),
      columnHelper.accessor((row) => row.stock_number, {
        id: "stock_number",
        cell: (info) => info.getValue(),
        header: 'Stock #',
        size: 180,
      }),
      columnHelper.accessor(formatCellVehicle, {
        id: "year",
        cell: (info) => info.getValue(),
        header: 'Vehicle',
        size: 350,
      }),
      ...(inventory === 'used' ? [
        {
          filterColumnId: EProviderInitials.CarGurus,
          ...columnHelper.accessor('cgr_deal_rating',
            {
                id: "cgr_deal_rating",
                cell: (info) => {
                    const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: 'CarGurus Rank',
                size: 200,
                meta: {
                  headerStyle: {
                      backgroundColor: '#fef0c7'
                  },
                  showTooltip: false
                }
            }
          ),
        },
        {
          filterColumnId: EProviderInitials.Autotrader,
          ...columnHelper.accessor('atc_deal_rating',
            {
                id: "atc_deal_rating",
                cell: (info) => {
                    const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: 'AutoTrader Rank',
                size: 200,
                meta: {
                  headerStyle: {
                      backgroundColor: '#eaecf5'
                  },
                  showTooltip: false
                }
            }
          ),
        },
        {
          filterColumnId: EProviderInitials.CarsDotCom,
            ...columnHelper.accessor('ccm_deal_rating',
                {
                    id: "ccm_deal_rating",
                    cell: (info) => {
                        const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
                        return <Badge color={color} icon={icon} label={label} size="sm" />;
                    },
                    header: 'Cars.com Rank',
                    size: 200,
                    meta: {
                      headerStyle: {
                          backgroundColor: '#ffe6d5'
                      },
                      showTooltip: false
                    }
                }
            ),
        },
        {
          filterColumnId: EProviderInitials.Carfax,
          ...columnHelper.accessor('cfx_deal_rating',
            {
              id: "cfx_deal_rating",
              cell: (info) => {
                const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
                return <Badge color={color} icon={icon} label={label} size="sm" />;
              },
              header: 'Carfax Rank',
              size: 200,
              meta: {
                headerStyle: {
                    backgroundColor: '#ffe6d5'
                },
                showTooltip: false
              }
            }
          ),
        },
      ] : []),
      {
        ...columnHelper.accessor('vdps', {
          id: "vdps",
          cell: (info) => info.getValue() || 0,
          header: 'VDPs',
          size: 200,
        }),
      },
      {
        ...columnHelper.accessor('leads', {
          id: "leads",
          cell: (info) => info.getValue() || 0,
          header: 'Leads',
          size: 200,
        }),
      },
      {
        ...columnHelper.accessor('leads_vdps', {
          id: "leads_vdps",
          cell: (info) => info.getValue(),
          header: 'Leads/VDP',
          size: 200,
        }),
      },
      columnHelper.accessor('price', {
        id: "price",
        cell: (info) => `$${Number(info.getValue()).toLocaleString()}`,
        header: 'Price',
        size: 200,
      }),
      columnHelper.accessor('last_price_change', {
        id: "last_price_change",
        cell: (info) => (info.getValue() === null ? "N/A" : info.getValue()),
        header: 'Last Price Change',
        size: 220,
      }),
      ...(inventory === 'used' ? [
        columnHelper.accessor("default_percentage_to_market", {
          cell: (info) =>
            info.getValue() === null
              ? "N/A"
              : `${(info.getValue() * 100).toLocaleString()}%`,
          header: 'Default % of Market',
          id: "default_percentage_to_market",
          size: 250,
        }),
      ] : []),
      ...(inventory === 'new' ? [
        columnHelper.accessor("price_to_market", {
          cell: (info) =>
            info.getValue() === null
              ? "N/A"
              : `${(info.getValue() * 100).toLocaleString()}%`,
          header: 'Price to Market',
          id: "percentage_to_market",
          size: 220,
        }),
      ] : []),
      columnHelper.accessor('dol', {
        id: "dol",
        cell: (info) => info.getValue(),
        header: 'DOL',
        size: 200,
      }),
      columnHelper.accessor('photos_count', {
        id: "photos_count",
        cell: (info) => info.getValue(),
        header: 'Photos',
        size: 200,
      }),
      ...(inventory === 'used' ? [
        columnHelper.accessor('total_carfax_pull', {
          id: "total_carfax_pull",
          cell: (info) => info.getValue(),
          size: 200,
          header: 'CarFax Pulls'
        }),
      ] : [])
    ]
  );