import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import Cookies from 'js-cookie';
import ELocalStorageKeys from "types/LocalStorageKey";
import { TInventoryOption, inventories } from "constants/common";
import { ELocalSavedFilters, ISavedDefaultFilters, getDefaultFilters, setDefaultFilters } from "utils/defaultFilters";
import EUserType from "types/UserTypes";
import { TDealer } from "api/dealershipApi";
import { UserRolesEnum } from "enums/UserRolesEnum";

type VerifyTokenParams = {
  email: string;
  token: string;
};

type VerifyTokenPayload = {
  body: Body;
}

type Body = {
  message: string;
}

export const verifyToken = createAsyncThunk('auth/verifyToken', async (params: VerifyTokenParams, thunkAPI) => {
  const url = `${process.env.REACT_APP_API_URL}api/v2/users/verify_token`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email_address: params.email,
      token: params.token,
    }),
  });
  
  if (!response.ok) {
    return thunkAPI.rejectWithValue({
      ...await response.json(),
      statusCode: response.status,
    });
  }

  const data = await response.json();

  return {
    ...data,
    statusCode: response.status,
  };

});

type LoginUserParams = {
  email: string;
  password: string;
  recaptchaToken: string | null;
};

export const loginUser = createAsyncThunk('auth/login', async (params: LoginUserParams, thunkAPI) => {
  
  const url = `${process.env.REACT_APP_API_URL}api/v2/users/login`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email_address: params.email,
      password: params.password,
      recaptcha_token: params.recaptchaToken,
    }),
  });

  if (!response.ok) {
    return thunkAPI.rejectWithValue({
      ...await response.json(),
      statusCode: response.status,
    });
  }

  const data = await response.json();

  return {
    ...data,
    statusCode: response.status,
  };

});

export const logoutUser = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  
  const url = `${process.env.REACT_APP_API_URL}api/v2/users/logout`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get(ELocalStorageKeys.AccessToken)}`,
    },
  });
  
  if (!response.ok) {
    return thunkAPI.rejectWithValue({
      ...await response.json(),
      statusCode: response.status,
    });
  }
  
  const data = await response.json();

  return {
    ...data,
    statusCode: response.status,
  };

});

const defaultInventory = getDefaultFilters<
  ISavedDefaultFilters[ELocalSavedFilters.SelectedInventoryHeader]
>(ELocalSavedFilters.SelectedInventoryHeader, inventories[0]);

// Get the current URL
const url = window.location.href;

// Create a URL object
const urlObj = new URL(url);

// Get the search parameters
const searchParams = new URLSearchParams(urlObj.search);

const inventory = searchParams.get("inventory");

const inventoryParams = inventories.find((inv) => inv.value === inventory);

interface IResultsManager {
  email: string;
  name: string;
  phone: string;
  secondaryPhone: string;
}

export interface IDealer {
  name: string;
  uid: string;
  resultsManagers: IResultsManager[];
  is_vinsolutions: boolean;
}


type LoginPayload = {
  body: {
    access_token: string;
    refresh_token: string;
    jwt: {
      Username: string;
    };
    roles: UserRolesEnum[];
  }
};

// Define a type for the slice state
export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  email: string | null;
  inventory: TInventoryOption;
  dealer: IDealer;
  type: EUserType;
  dealerV2: TDealer;
  roles: UserRolesEnum[]
}

// Define the initial state using that type
const initialState: AuthState = {
  accessToken: Cookies.get(ELocalStorageKeys.AccessToken) || null,
  refreshToken: Cookies.get(ELocalStorageKeys.RefreshToken) || null,
  email: Cookies.get(ELocalStorageKeys.UserEmail) || null,
  inventory: inventoryParams ?? defaultInventory ?? inventories[0], // inventories[1]
  dealer: {
    is_vinsolutions: false,
    name: "",
    uid: "",
    resultsManagers: [],
  },
  dealerV2: {
    is_vinsolutions: false,
    name: "",
    new: true,
    used: true,
    results_managers: [],
    uid: "",
  },
  type: EUserType.Admin,
  roles: Cookies.get(ELocalStorageKeys.UserRoles) ? JSON.parse(Cookies.get(ELocalStorageKeys.UserRoles)!) : [],
};

const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setInventory: (state, action: PayloadAction<Pick<AuthState, 'inventory'>>) => {
      state.inventory = action.payload.inventory;
    },
    setDealer: (state, action: PayloadAction<Pick<AuthState, 'dealer'>>) => {
      
      setDefaultFilters<ISavedDefaultFilters[ELocalSavedFilters.SelectedDealerHeader]>(ELocalSavedFilters.SelectedDealerHeader, action.payload.dealer);
      
      state.dealer = action.payload.dealer;
    
    },

    setDealerV2: (state, action: PayloadAction<Pick<AuthState, 'dealerV2'>>) => {
      setDefaultFilters<ISavedDefaultFilters[ELocalSavedFilters.SelectedDealerHeaderV2]>(ELocalSavedFilters.SelectedDealerHeaderV2, action.payload.dealerV2);
      state.dealerV2 = action.payload.dealerV2;
    }
    
  },
  extraReducers(builder) {
    
    builder.addCase(loginUser.fulfilled, (state, action: PayloadAction<LoginPayload>) => {

      Cookies.set(ELocalStorageKeys.AccessToken, action.payload.body.access_token!, { secure: true, sameSite: 'strict' }); 
      Cookies.set(ELocalStorageKeys.RefreshToken, action.payload.body.refresh_token!, { secure: true, sameSite: 'strict' }); 
      Cookies.set(ELocalStorageKeys.UserEmail, action.payload.body.jwt.Username!, { secure: true, sameSite: 'strict' }); 
      Cookies.set(ELocalStorageKeys.UserRoles, JSON.stringify(action.payload.body.roles), { secure: true, sameSite: 'strict' });

      state.accessToken = action.payload.body.access_token;
      state.refreshToken = action.payload.body.refresh_token;
      state.email = action.payload.body.jwt.Username;
      state.roles = action.payload.body.roles;
    });

    builder.addCase(logoutUser.fulfilled, (state) => {
      Cookies.remove(ELocalStorageKeys.AccessToken); 
      Cookies.remove(ELocalStorageKeys.RefreshToken); 
      Cookies.remove(ELocalStorageKeys.UserEmail); 
      Cookies.remove(ELocalStorageKeys.UserRoles);
      
      state.accessToken = null;
      state.refreshToken = null;
      state.email = null;
      state.roles = [];
    });

    builder.addCase(verifyToken.rejected, (state) => {
      Cookies.remove(ELocalStorageKeys.AccessToken); 
      Cookies.remove(ELocalStorageKeys.RefreshToken); 
      Cookies.remove(ELocalStorageKeys.UserEmail);
      Cookies.remove(ELocalStorageKeys.UserRoles); 
      
      state.accessToken = null;
      state.refreshToken = null;
      state.email = null;
      state.roles = [];
    });

    builder.addCase(verifyToken.fulfilled, (state, action: PayloadAction<VerifyTokenPayload>) => {
      // console.log('verifyToken.fulfilled', action.payload);
    });
  },
});

export const { setInventory, setDealer, setDealerV2 } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;

export const selectIsAuthenticated = (state: RootState) => state.auth.accessToken !== null;

export const selectUserData = (state: RootState) => state.auth;

export const selectUserRoles = (state: RootState) => state.auth.roles;

export default authSlice.reducer;
