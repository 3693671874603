import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import usePostSales, { EModules } from "api/usePostSales";
import Button from "components/Button";
import Typography from "components/Typography";
import TextInput from "components/TextInput";
import Modal from "components/Modal";
import ModalBody from "components/Modal/components/Body";
import ModalHeader from "components/Modal/components/Header";
import { EProviderInitials } from "types/APIProviderRanks";
import getProviderName from "utils/getProviderName";
import styles from "./styles.module.scss";
import { IEditBudgetState } from "../../types";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

interface IEditBudgetModalProps extends IEditBudgetState {
  onClose: () => void;
  setRefreshTable: Dispatch<SetStateAction<number>>;
}

interface IBudgetForm {
  budget: number;
  provider: EProviderInitials;
}

const budgetFormSchema = yup.object().shape({
  budget: yup
    .number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : value
    )
    .required("Budget is required"),
});

const EditBudgetModal: FC<IEditBudgetModalProps> = ({
  isOpen,
  onClose,
  provider,
  setRefreshTable,
  value,
}) => {
  const { formState, handleSubmit, register, reset, setFocus } =
    useForm<IBudgetForm>({
      resolver: yupResolver(budgetFormSchema),
    });

  const userAuth = useAppSelector(selectUserData);
  
  const { isLoading, mutate } = usePostSales<EModules.SetBudgetROI>({
    module: EModules.SetBudgetROI,
    // params: { 
    //   dealership_uid: userAuth.dealerV2.uid, 
    // },
  });

  const onResetForm = useCallback(() => {
    if (provider && isOpen) {
      setFocus("budget");
      reset({ budget: value, provider });
    }
  }, [isOpen, provider, reset, setFocus, value]);

  useEffect(() => {
    if (isOpen && provider) {
      onResetForm();
    }
  }, [isOpen, onResetForm, provider]);

  const onSuccessEdit = useCallback(() => {
    toast.success(
      `${provider && getProviderName(provider)} budget has been updated`
    );
    onClose();
    setRefreshTable((prevState) => ++prevState);
  }, [onClose, provider, setRefreshTable]);

  const onSubmit = useCallback(
    async (form: IBudgetForm) => {
      mutate(
        { 
          budget: form.budget, 
          source: form.provider,
          dealership_uid: userAuth.dealerV2.uid,
          inventory: userAuth.inventory.value,
        },
        { onSuccess: () => onSuccessEdit() }
      );
    },
    [mutate, onSuccessEdit, userAuth.dealerV2.uid, userAuth.inventory.value]
  );

  const { errors } = formState;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <Typography fontSize="text-lg" fontWeight="semibold">
          {provider && getProviderName(provider)} Budget
        </Typography>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            disabled={isLoading}
            label={(
              <Typography fontSize="text-sm" fontWeight="semibold">
                Please add the MONTHLY BUDGET for this vendor
              </Typography>
            
            )}
            type="number"
            name="budget"
            register={register}
            errorMessage={errors.budget?.message as string}
          />
          <Button
            className={styles.submitButton}
            disabled={isLoading}
            size="lg"
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default EditBudgetModal;
