import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useGetSales, {
  EModules,
  IAPIPreShootReport,
  TPreShootReportOrderKeys,
  getSalesAsync,
} from "api/useGetSales";
import Table from "components/Table";
import { ESortOrder, ISortState, TSort } from "components/Table/types";
import TableDateRangeLabel from "components/TableDateRangeLabel";
import {
  TActionProps,
  TGeneratePdfFunction,
  TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import apiDateFormat from "constants/apiDateFormat";
import { lastWeek as defaultDateRange } from "constants/commonDateRanges";
import { pageSizeOptions } from "constants/defaultPageSizes";
import { useDebounce } from "hooks";
import {
  ELocalSavedFilters,
  ISavedDefaultFilters,
  getDefaultFilters,
  setDefaultFilters,
  tableFiltersWithProviders,
} from "utils/defaultFilters";
import formatOrderingAPI from "utils/formatOrderingAPI";
import { columns, pdfColumns, xlsColumns } from "./constants";
import { onGeneratePDFDefaultType } from "utils/helper";
import useUrlQuery from "hooks/useUrlQuery";
import { PreShootReportUrlParams } from "utils/urlParams";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";

type TSavedFilters =
  ISavedDefaultFilters[ELocalSavedFilters.PreShootReportTable];

const PreShootReport = () => {

  const userAuth = useAppSelector(selectUserData);

  const { getParam, setParam } = useUrlQuery<PreShootReportUrlParams>();

  const sortByParams = getParam("sortBy");
  const searchParams = getParam("search");

  const defaultFilters = getDefaultFilters<TSavedFilters>(
    ELocalSavedFilters.PreShootReportTable,
    tableFiltersWithProviders
  );

  // const [offset, setOffset] = useState<number>(defaultOffset);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);
  
  const [sortBy, setSortBy] = useState<ISortState>(
    sortByParams && typeof sortByParams === "string"
      ? {
          column: sortByParams.replace("-", ""),
          order: sortByParams.startsWith("-")
            ? ESortOrder.Descending
            : ESortOrder.Ascending,
        }
      : {
          column: null,
          order: ESortOrder.None,
        }
  );

  const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");
  const quickSearchApi = useDebounce<string>(quickSearch, 350);

  useEffect(() => {
    setParam({
      ...(sortBy.order && sortBy.column && {
        sortBy: sortBy.order === ESortOrder.Ascending ? sortBy.column : `-${sortBy.column}`,
      }),
      search: quickSearchApi,
      inventory: userAuth.inventory.value,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy.column, sortBy.order, quickSearchApi, userAuth.inventory.value]);
  
  const fetchParameters = useMemo(
    () => ({
      module: EModules.PreShootReportV2,
      params: {
        date_from: format(defaultDateRange.startDate, apiDateFormat),
        date_to: format(defaultDateRange.endDate, apiDateFormat),
        dealership_uid: userAuth.dealerV2.uid,
        // limit: pageSize,
        // offset,
        page: page + 1,
        page_size: pageSize,
        ordering: formatOrderingAPI<TPreShootReportOrderKeys>(sortBy),
        search: quickSearchApi,
        inventory: userAuth.inventory.value,
      },
    }),
    [page, pageSize, quickSearchApi, sortBy, userAuth.dealerV2.uid, userAuth.inventory.value]
  );
  const { data, isFetching } =
    useGetSales<EModules.PreShootReportV2>(fetchParameters);

  useEffect(() => {
    setDefaultFilters<TSavedFilters>(ELocalSavedFilters.PreShootReportTable, {
      pageSize,
    });
  }, [pageSize]);

  const onOffsetChange = useCallback((newOffset: number) => {
    // setOffset(newOffset);
  }, []);

  const onPageIndexChange = useCallback((newPageIndex: number) => {
    setPage(newPageIndex);
  }, []);

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const onSort: TSort["onSort"] = useCallback(
    ({ column, order }: ISortState) => {
      setSortBy({ column, order });
      // setParam({
      //   ...(order && column && {
      //     sortBy: order === ESortOrder.Ascending ? column : `-${column}`,
      //   })
      // });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onChangeQuickSearch = useCallback(
    (newSearch: string) => {
      setQuickSearch(newSearch);
      // setParam({ 
      //   search: newSearch
      //  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
    async (onGeneratePDF, setIsLoading) => {
      setIsLoading(true);

      try {
        let hasNextPage = true;
        let collectedResults: IAPIPreShootReport[] = [];
        // let mapOffset = 0;

        let tempPage = 1;

        do {
          const { next, results } =
            await getSalesAsync<EModules.PreShootReportV2>({
              ...fetchParameters,
              params: { 
                ...fetchParameters.params, 
                page: tempPage
                // offset: mapOffset 
              },
            })();
          collectedResults = [...collectedResults, ...results];

          tempPage++;
          hasNextPage = !!next;
          // mapOffset = mapOffset + pageSize;
        } while (hasNextPage);

        const bodyFormatted = collectedResults.map((values) => {
          return pdfColumns.map(
            ({ func, key }) => func?.(values[key]) || values[key]
          );
        });

        const dateRangeTitle = `${format(defaultDateRange.endDate, "MMM d, yyyy")}`;

        onGeneratePDF(
          {
            head: [pdfColumns.map(({ label }) => label)],
            body: bodyFormatted,
            ...onGeneratePDFDefaultType(`Pre Shoot Report`, dateRangeTitle)
          },
          "pre-shoot-report.pdf",
          {
            compress: true
          }
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export PDF file. Please contact your admin");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchParameters, pageSize]
  );

  const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
    async (generateXLS, setIsLoading) => {
      setIsLoading(true);

      try {
        let hasNextPage = true;
        let collectedResults: IAPIPreShootReport[] = [];
        // let mapOffset = 0;

        let tempPage = 1;

        do {
          const { next, results } =
            await getSalesAsync<EModules.PreShootReportV2>({
              ...fetchParameters,
              params: { 
                ...fetchParameters.params, 
                page: tempPage
                // offset: mapOffset 
              },
            })();
          collectedResults = [...collectedResults, ...results];

          tempPage++;
          hasNextPage = !!next;
          // mapOffset = mapOffset + pageSize;
        } while (hasNextPage);

        const bodyFormatted = collectedResults.map((values) => {
          return xlsColumns.map(
            ({ func, key }) => func?.(values[key], values) || values[key]
          );
        });

        generateXLS(
          {
            head: [xlsColumns.map(({ label }) => label)],
            body: bodyFormatted,
          },
          "pre-shoot-report.xls",
        );
        setIsLoading(false);
      } catch (err) {
        toast.error("Can't export XLS file. Please contact your admin");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchParameters, pageSize]
  );
  
  return (
    <>
      <TableDateRangeLabel
        endDate={defaultDateRange.endDate}
        title="This Week's Snapshot"
      />
      <Table<IAPIPreShootReport>
        columns={columns}
        data={data?.results || []}
        filterMode="server"
        isLoading={isFetching}
        limit={pageSize}
        onGeneratePDF={onGeneratePDF}
        onDownloadPDF={onGeneratePDF}
        onDownloadXLS={onGenerateXLSX}
        onOffsetChange={onOffsetChange}
        onPageSizeChange={onPageSizeChange}
        onPageIndexChange={onPageIndexChange}
        pageSizeOptions={pageSizeOptions}
        quickSearchFilter={{
          value: quickSearch,
          onChange: onChangeQuickSearch,
        }}
        sort={{
          column: sortBy.column,
          mode: "server",
          onSort,
          order: sortBy.order,
        }}
        total={data?.count}
        actionVersion="v2"
        actionConfig={{
          isDownloadPDF: true,
          isPrintPDF: true,
          isEmailPDF: true,
          isDownloadXLS: true,
          isEmailXLS: true
        }}
      />
    </>
  );
};

export default PreShootReport;
