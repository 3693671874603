import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import DataTable from "components/v2/DataTable";
import { getColumns } from "./constant";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { filterColumnsV2 } from "utils/filterColumns";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import { IProviderCheckList } from "types/IProviderCheckList";
import { ELocalSavedFilters, getDefaultFilters, ISavedDefaultFilters, setDefaultFilters, tableFiltersWithProviders } from "utils/defaultFilters";
import useUrlQuery from "hooks/useUrlQuery";
import { VDPResultsUrlParams } from "utils/urlParams";
import useGetReports, { EReportsModules, getReportsAsync, IAPIRecentActivityReportV2 } from "api/useGetReports";
import { IDropdownFilterKeyPair, PaginationState } from "components/Table";
import formatOrderingAPI from "utils/formatOrderingAPI";
import { TRecentActivityReportOrderKeys } from "api/useGetSales";
import { format, set } from "date-fns";
import DateRange, { IDateRangeProps } from "components/DateRange";
import { allTime, commonDateRangesV2 } from "constants/commonDateRanges";
import apiDateFormat from "constants/apiDateFormat";
import { useDebounce } from "hooks";
import { ColumnSizingState, SortingState, VisibilityState } from "@tanstack/react-table";
import { ESortOrder, ISortState } from "components/Table/types";
import { Box, Text } from "@chakra-ui/react";
import { CustomizeProvider } from "components/v2/CustomizeProvider";
import DolSelect from "components/v2/Select/DolSelect";
import { toast } from "react-toastify";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import { getPDFColumns, getXlsColumns } from "pages/VDPResults/constants";
import { TActionProps, TGeneratePdfFunction, TGenerateXLSFunction } from "components/Table/components/ActionButtons/types";

type TSavedFilters = ISavedDefaultFilters[ELocalSavedFilters.VDPResultTable];

export const VDPResultsV2 = () => {

    const userAuth = useAppSelector(selectUserData);

    const { getParam, getMultipleParams, setMultipleParams } = useUrlQuery<VDPResultsUrlParams>();

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");
    const dolRangeParams = getParam("dolRange");
    const sourceParams = getMultipleParams("source");
    const resultScoreParams = getParam("resultScore");
    const sortByParams = getParam("sortBy");
    const searchParams = getParam("search");
    const selectedDolRangeParams = getParam("selectedDolRange");
    const missingPhotosParams = getParam("is_missing_photos");
    const lessThan50VDPsParams = getParam("is_less_than_50_vdps");
    const dolMinParams = getParam("dol_min");
    const dolMaxParams = getParam("dol_max");

    const defaultFilters = getDefaultFilters<TSavedFilters>(
      ELocalSavedFilters.VDPResultTable,
      tableFiltersWithProviders
    );

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultFilters.columnVisibility || {});

    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(defaultFilters.columnSizing || {});

    const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
        // isFromNeedsAttention ? needsAttentionDateRange : defaultFilters.dateRange
        {
          startDate: startDateParams ? new Date(startDateParams) : allTime.startDate,
          endDate: endDateParams ? new Date(endDateParams) : allTime.endDate,
        }
    );

    const dolOptions: IDropdownFilterKeyPair[] = useMemo(() => [
        {
            label: 'DOL',
            value: '0',
        },
        {
            label: 'Over 7',
            value: '7',
        },
        {
            label: 'Over 15',
            value: '15',
        },
        {
            label: 'Over 30',
            value: '30',
        },
        {
            label: 'Over 60',
            value: '60',
        },
    ], []);

    const findOption = selectedDolRangeParams ? dolOptions.find((option) => option.value === parseInt(selectedDolRangeParams)) : dolOptions[0];

    const [selectedDol, setSelectedDol] = useState<IDropdownFilterKeyPair>(findOption ?? dolOptions[0]);

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", "") as TRecentActivityReportOrderKeys,
        desc: sortByParams.startsWith("-") ? true : false,
    }] : [{ id: 'vdps', desc: true }]);

    const [providers, setProviders] = useState<IProviderCheckList[]>(
        defaultFilters.providers
    );

    const fetchProvders = useMemo(() => ({
        module: EProvidersModules.ThirdPartyProviderV2,
        params: {
          dealership_uid: userAuth.dealerV2.uid,
          inventory: userAuth.inventory.value,
        }
    }), [userAuth.dealerV2.uid, userAuth.inventory.value]);

    const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);
    
    const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");

    const quickSearchApi = useDebounce<string>(quickSearch, 350);
  
    // set providers
    useMemo(() => {
        if (!providerList.isFetching && providerList.data) {
        const tempProviders: IProviderCheckList[] = ((providerList.data as any).results as any[]).map((provider) => {
            return {
            id: provider.value,
            label: provider.label,
            value: sourceParams?.includes(provider.value.toString()) || false,
            }
        });
        setProviders(tempProviders);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerList.data, providerList.isFetching]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: 'dol',
                order: ESortOrder.Ascending,
            };
        }
        return {
            column: sortBy[0].id as TRecentActivityReportOrderKeys,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
            module: EReportsModules.RecentActivityReportV2,
            params: {
            ...(selectedDol.value === 0 && {
                dol_max: dolRangeParams ? isNaN(dolRangeParams.split('-').map(Number)[1]) ? 2000 : dolRangeParams.split('-').map(Number)[1] : 2000,
                dol_min: dolRangeParams ? dolRangeParams.split('-').map(Number)[0] : 0,
            }),
            ...(selectedDol && {
                dol_max: 2000,
                dol_min: selectedDol.value,
            }),
            ...(dolMinParams && {
                dol_min: dolMinParams ? parseInt(dolMinParams) : undefined,
            }),
            ...(dolMaxParams && {
                dol_max: dolMaxParams ? parseInt(dolMaxParams) : undefined,
            }),
            date_from: format(dateRange.startDate, apiDateFormat),
            date_to: format(dateRange.endDate, apiDateFormat),
            dealership_uid: userAuth.dealerV2.uid,
            page: page + 1,
            page_size: pageSize,
            // order,
            ordering: formatOrderingAPI<TRecentActivityReportOrderKeys>(sortedBy),
            search: quickSearchApi,
            source: providers.filter(({ value }) => value).map(({ id }) => id),
            ...(resultScoreParams && {
                result_score: resultScoreParams,
            }),
            inventory: userAuth.inventory.value,
            ...(missingPhotosParams && {
                is_missing_photos: missingPhotosParams,
            }),
            ...(lessThan50VDPsParams && {
                is_less_than_50_vdps: lessThan50VDPsParams,
            }),
            },
        }),
        [selectedDol, dolRangeParams, dolMinParams, dolMaxParams, dateRange.startDate, dateRange.endDate, userAuth.dealerV2.uid, userAuth.inventory.value, page, pageSize, sortedBy, quickSearchApi, providers, resultScoreParams, missingPhotosParams, lessThan50VDPsParams]
    );

    const { data, isFetching } = useGetReports<EReportsModules.RecentActivityReportV2>(fetchParameters);

    useEffect(() => {

        const selectedProviders = providers.filter(({ value }) => value).map(({ id }) => id);
    
        setMultipleParams({
          startDate: format(dateRange.startDate, apiDateFormat),
          endDate: format(dateRange.endDate, apiDateFormat),
          ...(selectedDol && {
            dolRange: `${selectedDol.value}-2000`,
          }),
          source: selectedProviders,
          selectedDolRange: (selectedDol as IDropdownFilterKeyPair).value.toString(),
          inventory: userAuth.inventory.value,
        });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.startDate, dateRange.endDate, selectedDol.value, providers, userAuth.inventory.value]);
    
    useEffect(() => {
        setDefaultFilters<TSavedFilters>(ELocalSavedFilters.VDPResultTable, {
            dateRange,
            pageSize,
            providers,
            columnVisibility,
            columnSizing,
        });
    }, [dateRange, pageSize, providers, columnVisibility, columnSizing]);
    
    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
          setIsLoading(true);
    
          try {
            let hasNextPage = true;
            let collectedResults: IAPIRecentActivityReportV2[] = [];
            // let mapOffset = 0;
    
            let tempPage = 1;
    
            do {
              const { next, results } =
                await getReportsAsync<EReportsModules.RecentActivityReportV2>({
                  ...fetchParameters,
                  params: {
                    ...fetchParameters.params,
                    page_size: 1000,
                    page: tempPage,
                  },
                })();
              collectedResults = [...collectedResults, ...results];
    
              hasNextPage = !!next;
              tempPage++;
            } while (hasNextPage);
    
            // filter providers
            const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
              // cgr_deal_rating
              // atc_deal_rating
              // ccm_deal_rating
              return providers.id.toLowerCase();
            });
    
            // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
            const filteredXlsColumns = getXlsColumns(userAuth.inventory.value).filter(({ key }) => {
              if (key.includes('_deal_rating')) {
                return filteredProviders.includes(key.split('_deal_rating')[0]);
              }
              return true;
            });
    
            const bodyFormatted = collectedResults.map((values) => {
              return filteredXlsColumns.map(
                ({ func, key }) =>
                  func?.(values[key], values) || (values[key] as string)
              );
            });
    
            generateXLS(
              {
                head: [filteredXlsColumns.map(({ label }) => label)],
                body: bodyFormatted,
              },
              "vdp-results.xls"
            );
            setIsLoading(false);
          } catch (err) {
            toast.error("Can't export XLS file. Please contact your admin");
          }
        },
        [fetchParameters, providers, userAuth.inventory.value]
      );
    
      const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading) => {
          setIsLoading(true);
    
          try {
            let hasNextPage = true;
            let collectedResults: IAPIRecentActivityReportV2[] = [];
            // let mapOffset = 0;
    
            let tempPage = 1;
    
            do {
              const { next, results } =
                await getReportsAsync<EReportsModules.RecentActivityReportV2>({
                  ...fetchParameters,
                  params: {
                    ...fetchParameters.params,
                    page_size: 1000,
                    page: tempPage,
                  },
                })();
              collectedResults = [...collectedResults, ...results];
    
              hasNextPage = !!next;
              tempPage++;
            } while (hasNextPage);
    
            // filter providers
            const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
              // cgr_deal_rating
              // atc_deal_rating
              // ccm_deal_rating
              return providers.id.toLowerCase();
            });
    
            // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
            const filteredPDFColumns = getPDFColumns(userAuth.inventory.value).filter(({ key }) => {
              if (key.includes('_deal_rating')) {
                return filteredProviders.includes(key.split('_deal_rating')[0]);
              }
              return true;
            });
    
            const bodyFormatted = collectedResults.map((values) => {
              return filteredPDFColumns.map(
                ({ func, key }) =>
                  func?.(values[key], values) || (values[key] as string)
              );
            });
    
            const dateRangeTitle = formattedDateRange(dateRange);
    
            onGeneratePDF(
              {
                head: [filteredPDFColumns.map(({ label }) => label)],
                body: bodyFormatted,
                ...onGeneratePDFDefaultType(`VDP Results`, dateRangeTitle)
              },
              "vdp-results.pdf",
              {
                compress: true,
                orientation: "landscape",
              }
            );
            setIsLoading(false);
          } catch (err) {
            toast.error("Can't export PDF file. Please contact your admin");
          }
        },
        [dateRange, fetchParameters, providers, userAuth.inventory.value]
    );

    const filteredColumns = useMemo(
        () => filterColumnsV2(getColumns(userAuth.inventory.value), providers),
        [providers, userAuth.inventory.value]
    );

    const onPageChange = useCallback((pagination: PaginationState) => {
        setPage(pagination.pageIndex);
        setPageSize(pagination.pageSize);
    }, []);
    
    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
        setMultipleParams({
          sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
        });
    }, []);
    
    const onDateChange = (newValue: [Date, Date]) => {
        // setDateRange({ startDate, endDate });
        setDateRange({
            startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
            endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
        });
    };

    const onDolChange = (selectedDol: IDropdownFilterKeyPair) => {
      setSelectedDol(selectedDol);
      setSortBy([{ id: 'dol', desc: false }]);
    };

    const onProviderChange = (selectedProvider: IProviderCheckList) => {
        setProviders((prevProviders) =>
            prevProviders.map(provider =>
                provider.id === selectedProvider.id ? { ...provider, value: !provider.value } : provider
            )
        );
        
    };
    
    const onSearch = (search: string) => {
      setQuickSearch(search);
      setMultipleParams({
        search
      });
    };

    const columnSidebarChilrend = () => {
      if (!providers.length || userAuth.inventory.value === 'new') return null;
      return (
        <Box
          display="flex"
          flexFlow="column"
          gap="10px"
        >
          <Text
            fontWeight="600"
          >
            Providers
          </Text>
          <CustomizeProvider 
            providers={providers}
            onChange={onProviderChange}
          />
        </Box>
      );
    };

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap="15px"
                    flex="1"
                >
                    <DolSelect 
                        defaultValue={selectedDol}
                        options={dolOptions}
                        onChange={onDolChange}
                    />
                </Box>
                <Box>
                    <ActionButtonV2 
                        actionConfig={{
                            isDownloadPDF: true,
                            isDownloadXLS: true,
                            isEmailPDF: true,
                            isEmailXLS: true,
                            isPrintPDF: true
                        }}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="15px"
        >
            <DateRange 
                onChange={onDateChange}
                quickOptions={commonDateRangesV2}
                value={dateRange}
            />
            <DataTable<IAPIRecentActivityReportV2>
                data={data?.results || []}
                rowCount={data?.count || 0}
                onPageChange={onPageChange}
                sort={sortBy}
                onSort={onSort}
                columns={filteredColumns as any}
                pageIndex={page}
                pageSize={pageSize}
                isLoading={isFetching}
                conttroller={conttroller()}
                search={quickSearchApi}
                onSearch={onSearch}
                pageCount={data?.total_pages}
                onColumnVisibilityChange={setColumnVisibility}
                columnVisibility={columnVisibility}
                onColumnSizingChange={setColumnSizing}
                columnSizing={columnSizing}
                showColumns={true}
                columnsSidebarChildren={columnSidebarChilrend()}
                // onUpdateData={updateData}
            />
        </Box>
    );
}