import Table from "components/Table";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import useGetSales, { EModules, SourceType, VDPROIResultV2, getSalesAsync } from "api/useGetSales";
import { IDateRangeProps } from "components/DateRange";
import { ELocalSavedFilters, ITableMarketing, getDefaultFilters, setDefaultFilters } from "utils/defaultFilters";
import commonDateRanges, { ICommonDateRange, lastWeek } from "constants/commonDateRanges";
import apiDateFormat from "constants/apiDateFormat";
import { TActionProps, TGeneratePdfFunction, TGenerateXLSFunction } from "components/Table/components/ActionButtons/types";
import { toast } from "react-toastify";
import { formatNumber, formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import { Alert, AlertIcon, Box, Radio, RadioGroup, Stack, Text, Tooltip } from "@chakra-ui/react";
// import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import { useUrlQuery } from "hooks/useUrlQuery";
import { VDPROIUrlParams } from "utils/urlParams";
import { TInventoryOption, TInventoryOptionValue, inventories } from "constants/common";
import { CellDef } from "jspdf-autotable";
import  { QuestionOutlineIcon } from "@chakra-ui/icons";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { selectUserData, setInventory } from "store/auth/authSlice";

const VDPROI: React.FC = () => {

  const userAuth = useAppSelector(selectUserData);

  const dispatch = useAppDispatch();

  const { getParam, setParam } = useUrlQuery<VDPROIUrlParams>();

  const tempStartDate = getParam("startDate");
  const tempEndDate = getParam("endDate");
  const sourceType = getParam("sourceType");

  const columns = useMemo(() => getColumns(userAuth.inventory.value), [userAuth.inventory.value]);

  const defaultFilters = getDefaultFilters<ITableMarketing>(
    ELocalSavedFilters.VDPROITable,
    { dateRange: lastWeek }
  );

  const [groupType, setGroupType] = useState<SourceType>(sourceType !== null && (sourceType && (sourceType !== 'null' as any)) ? sourceType : "website");

  const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
    // defaultFilters.dateRange
    {
      startDate: tempStartDate ? new Date(tempStartDate) : defaultFilters.dateRange.startDate,
      endDate: tempEndDate ? new Date(tempEndDate) : defaultFilters.dateRange.endDate,
    }
  );

    useEffect(() => {
      setParam({
        startDate: format(dateRange.startDate, apiDateFormat),
        endDate: format(dateRange.endDate, apiDateFormat),
        sourceType: groupType,
        inventory: userAuth.inventory.value
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.startDate, dateRange.endDate, userAuth.inventory.value, groupType]);

  // get 3rd party providers
  // const fetchProvders = useMemo(() => ({
  //   module: EProvidersModules.ThirdPartyProviderV2,
  //   params: {
  //     dealership_uid: userAuth.dealerV2.uid,
  //     inventory: userAuth.inventory.value
  //   }
  // }), [userAuth.dealerV2.uid, userAuth.inventory.value]);

  // const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);

  const fetchParameters = useMemo(
    () => ({
      module: EModules.VDPROIV2,
      params: {
        date_from: format(dateRange.startDate, apiDateFormat),
        date_to: format(dateRange.endDate, apiDateFormat),
        dealership_uid: userAuth.dealerV2.uid,
        source_type: groupType,
        inventory: userAuth.inventory.value,
        ...(userAuth.inventory.value === 'all' && {
          inventory: '',
        }) as any,
        // ...(groupType === 'thirdParty' && !providerList.isLoading && providerList.data && {
        //   source: providerList.data.results.map((result) => result.value.toString()),
        // }),
      },
    }),
    [dateRange.endDate, dateRange.startDate, groupType, userAuth.dealerV2.uid, userAuth.inventory.value]
  );

  const { data, isFetching } = useGetSales<EModules.VDPROIV2>(fetchParameters);

  const onChangeDateRange: IDateRangeProps["onChange"] = useCallback(
    ([startDate, endDate]) => {
      setDateRange({ startDate, endDate });

      // set default values
      setDefaultFilters<ITableMarketing>(ELocalSavedFilters.VDPROITable, {
        dateRange: {
          startDate: startDate,
          endDate: endDate,
        },
      });

    }, []);

  const activeIndexQuickOption = useMemo(
    () =>
      commonDateRanges.findIndex(
        ({ value: quickOptionValue }) =>
          format(quickOptionValue.startDate, apiDateFormat) ===
            format(dateRange.startDate, apiDateFormat) &&
          format(quickOptionValue.endDate, apiDateFormat) ===
            format(dateRange.endDate, apiDateFormat)
      ),
    [dateRange.endDate, dateRange.startDate]
  );

  const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
    async (onGeneratePDF, setIsLoading) => {
      setIsLoading(true);
      try {
        const tempData = await getSalesAsync<EModules.VDPROIV2>(
          fetchParameters
        )();

        // const bodyFormatted = tempData ? tempData.results.map((values) => {
        //   return pdfColumns.map(
        //     ({ func, key }) => func?.(values[key]) || values[key]
        //   );
        // }) : [];

        const pdfColumns = getPdfColumns(userAuth.inventory.value);

        const bodyFormatted = tempData ? tempData.results.map((values, index) => {
          return pdfColumns.map(({ func, key }) => {
            if (key === 'rank') {
              return index + 1;
            }
            return func?.(values[key]) || values[key];
          });
        }) : [];

        const dateRangeTitle = activeIndexQuickOption !== -1 ? `${commonDateRanges[activeIndexQuickOption].label} (${formattedDateRange(dateRange)})` : formattedDateRange(dateRange);

        const customRows: CellDef[][] = [
          [
            {
              title: `Total VDP's`,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: '',
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: formatNumber(data?.grand_total ?? 0),
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            }
          ],
          [
            {
              title: `Total Sold`,
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: '',
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            },
            {
              title: formatNumber(data?.total_sold ?? 0),
              styles: { textColor: [0, 0, 0], fontStyle: 'bold', halign: 'center', }
            }
          ]
        ];

        onGeneratePDF(
          {
            head: [pdfColumns.map(({ label }) => label)],
            body: [
              ...bodyFormatted,
              // Add total row to foot
              ...customRows,
            ],
            ...onGeneratePDFDefaultType(`VDP ROI`, dateRangeTitle)
          },
          "vdp-roi.pdf",
          {
            compress: true
          }
        );
        setIsLoading(false);
      } catch (err: any) {
        toast.error("Can't export PDF file. Please contact your admin");
      }
    },
    [activeIndexQuickOption, data?.grand_total, data?.total_sold, dateRange, fetchParameters, userAuth.inventory.value]
  );

  const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
    async (generateXLS, setIsLoading) => {
      setIsLoading(true);
      try {
        const tempData = await getSalesAsync<EModules.VDPROIV2>(
          fetchParameters
        )();

        // const bodyFormatted = tempData ? tempData.results.map((values) => {
        //   return pdfColumns.map(
        //     ({ func, key }) => func?.(values[key]) || values[key]
        //   );
        // }) : [];

        const bodyFormatted = tempData ? tempData.results.map((values, index) => {
          return getXLSColumns(userAuth.inventory.value).map(({ func, key }) => {
            if (key === 'rank') {
              return index + 1;
            }
            return func?.(values[key], values) || values[key];
          });
        }) as (string | number)[][] : [];


        generateXLS(
          {
            head: [getXLSColumns(userAuth.inventory.value).map(({ label }) => label)],
            body: bodyFormatted,
          },
          "vdp-roi.xls"
        );
        setIsLoading(false);
      } catch (err: any) {
        toast.error("Can't export XLS file. Please contact your admin");
      }
    },
    [fetchParameters, userAuth.inventory.value]
  );

  // const onUpdateGroupTYpe = (type: SourceType) => {
  //     setGroupType(type);
  //     setParam({ sourceType: type });
  // };

  const onRadioChange = (nextValue: string) => {
    setGroupType(nextValue as SourceType);
  };

  const onInventoryRadioChange = (nextValue: TInventoryOptionValue) => {
    const selectedInventory: TInventoryOption = inventories.find((inventory) => inventory.value === nextValue) || inventories[0];
    dispatch(setInventory({ inventory: selectedInventory }));
  };

  const filteredDateRanges: ICommonDateRange[] = commonDateRanges.filter(range => {
    return range.label !== "Last 30 Days" && range.label !== "Last 60 Days";
  });

  const filteredInventories = useMemo(() => {
    if (userAuth.dealerV2.new && userAuth.dealerV2.used) {
        return inventories;
    }
    return inventories.filter((inventory) => {
        if (userAuth.dealerV2.used && inventory.value === 'used') {
            return true;
        }
        if (userAuth.dealerV2.new && inventory.value === 'new') {
            return true;
        }
        return false;
    });
  }, [userAuth.dealerV2.new, userAuth.dealerV2.used]);

  const customButtons = (): React.ReactNode => (
    <Box
      display="flex" 
      alignItems="center" 
      justifyContent="space-between"
      gap="10px" 
      flex="1"
      flexFlow="row wrap"
    >
      <Box display="flex" alignItems="flex-start" gap="10px" flex="1" paddingRight="20px" flexFlow="column wrap">
        <RadioGroup onChange={onInventoryRadioChange} value={userAuth.inventory.value}>
          <Stack direction='row' display="flex" flexFlow="row wrap">
            {filteredInventories.map((inventory) => (
              <Radio key={inventory.value} value={inventory.value} colorScheme='red'>
                {inventory.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <RadioGroup onChange={onRadioChange} value={groupType}>
          <Stack direction='row' display="flex" flexFlow="row wrap">
            <Radio value="website" colorScheme='red'>Website</Radio>
            <Radio value="third-party" colorScheme='red'>Third Parties</Radio>
            <Radio value="combine" colorScheme='red'>Combined</Radio>
          </Stack>
        </RadioGroup>
      </Box>
      <Box flex={1} justifyContent="flex-end" display="flex" alignItems="center" marginRight="10px">
        <Tooltip 
          hasArrow={true}
          label={
            <Box>
              <Text fontSize="sm" marginBottom="10px">
                VDP ROI measures the 80-98% of activity you cannot see in your CRM. We look at lifetime VDP's beginning with the first day a stock number is added in inventory.
              </Text>
              <Text fontSize="sm">
                From there we track all VDP's against that stock number including third parties you're paying for; any marketing campaign spends and even free sources of VDP's such as Google organic. By rank, the end result shows you which sources are helping you sell cars, and which are not.
              </Text>
            </Box>
          } 
          fontSize='md'
        >
          <QuestionOutlineIcon color="orange" />
        </Tooltip>
      </Box>
    </Box>
  );

  const formattedData = data && data.results ? data.results : [];

  return (
    <Box display="flex" flexFlow="column" gap="10px">
      <Box>
        <Table<VDPROIResultV2>
          dateRange={{ onChange: onChangeDateRange, value: dateRange, quickOptions: filteredDateRanges }}
          data={formattedData}
          columns={columns}
          isLoading={isFetching}
          onGeneratePDF={onGeneratePDF}
          onDownloadPDF={onGeneratePDF}
          onDownloadXLS={onGenerateXLSX}
          showPagination={false}
          noDataMessage="No Vehicle Sold"
          customButtons={customButtons()}
          columnTotal={{
            data: [
              {
                title: `Total VDP's`,
                value: formatNumber(data?.grand_total ?? 0)
              },
              {
                title: `Total Sold`,
                value: formatNumber(data?.total_sold ?? 0)
              }
            ]
          }}
          // sort={{ mode: "client" }} // disabled sorting
          actionVersion="v2"
          actionConfig={{
            isDownloadPDF: true,
            isDownloadXLS: true,
            isEmailPDF: true,
            isEmailXLS: true,
            isPrintPDF: true,
          }}
        />
      </Box>
      <Box>
        {userAuth.dealer.is_vinsolutions && (
            <Alert status='info' width="max-content" borderRadius="5px">
              <AlertIcon/>
              <Text fontSize="14px" fontStyle="italic">
                Sold count is based on Sold from Leads
              </Text>
            </Alert>
          )}
      </Box>
    </Box>
  );
};

export default VDPROI;
